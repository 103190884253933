<template>
  <div>
    <a
      v-for="doctor in filteredDocs"
      :key="doctor.id"
      :href="doctorRoute(doctor)"
      target="_blank"
      class="mb-2 hover-no-underline hover-color-green d-flex align-items-start"
    >
      <img
        class="object-cover w-98 h-98 rounded-lg mr-2"
        :src="doctorImage(doctor)"
        :alt="doctor.DoctorsTitle+' - '+doctor.Designation+doctorSpeciality(doctor)"
      />
      <div class="col px-0 min-width-0">
        <div class="d-flex align-items-start mb-1">
          <p
            class="f-base hover-target color-gray-600 font-weight-semi-bold mb-0"
          >
            {{ doctor.DoctorsTitle }}
          </p>
          <p
            v-if="doctor.Experience > 0"
            class="white-space-nowrap f-12 color-gray-600 mb-0 ml-auto"
          >
            {{ doctor.Experience }} years
          </p>
        </div>
        <p class="color-gray-600 f-14 mb-0 text-truncate">
          {{ doctor.Designation }}
        </p>
        <p class="color-gray-600 f-14 mb-0 text-truncate">
          {{ doctorSpeciality(doctor) }}
        </p>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: ["doctorsData", "doctorPath", "lib"],
  data() {
    return {
      docs: [],
      doctor: "",
      isSearching: false,
      limit: -1,
    };
  },
  created() {
    let vi = this;
    vi.docs = vi.doctorsData;
  },
  mounted() {
    let vi = this;
    vi.docs = vi.doctorsData;
  },
  methods: {
    doctorSpeciality(doctor) {
      let vi = this;
      let specialities = [];
      Array.from(doctor.speciality_data).forEach((spec) => {
        specialities.push(spec.Speciality);
      });
      return Array.from(specialities).join(" / ");
    },
    doctorImage(doctor) {
      let thumb;
      let vi = this;

      try {
        thumb = doctor.doctor_photo.image_data.thumb_url;
      } catch (e) {
        thumb = `${vi.assetPath}img/doctor-placeholder.jpg`;
      }
      return thumb;
    },
    doctorRoute(doctor) {
      let vi = this;
      return window.location.origin + "/" + vi.doctorPath + "/" + doctor.Slug;
    },
    refreshDoctors(name) {
      this.doctor = name;
      this.filteredDocs;
    },
  },
  computed: {
    filteredDocs() {
      let vi = this;
      if (vi.doctor.length > 0) {
        let vi = this;
        return vi.docs
          .filter((doc) => {
            return Boolean(
              doc.ApptDoctorName.trim()
              .match(new RegExp(`${vi.doctor.trim()}`, "gi"))
            )
              ? true
              : false;
          })
          .slice(
            0,
            vi.limit > 0 ? vi.limit : vi.limit < 0 ? vi.docs.length : 1
          );
      } else {
        return vi.docs.slice(
          0,
          vi.limit > 0 ? vi.limit : vi.limit < 0 ? vi.docs.length : 1
        );
      }
    },

    assetPath() {
      let vi = this;

      return typeof vi.lib === "string" && vi.lib.length > 0 ? vi.lib : "";
    },
  },
};
</script>
