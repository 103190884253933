<template>
  <div>
    <div>
            <div class="d-lg-flex align-items-center justify-content-center mb-4">
                <p class="color-base mb-0 mr-3 white-space-nowrap f-lg-20 f-18">I am</p>
                <select data-sel class="wide float-none col-lg-2" data-gender>
                    <option disabled selected value="">Select Gender</option>
                    <option v-for="gen in gendersAllData">{{ gen.Name }}</option>
                </select>
            </div>
        </div>
  </div>
</template>

<script>
export default {
  props: {
    gendersData: Array,
  },

  data() {    
    return {
      genders: [],
    };
  },
   mounted() {
      this.gendersArray()
   },
  methods:{
        gendersArray(){
          this.gendersData.map( (data) => {        
          this.genders.push(data.Name);
        });
       
          this.$emit("genders-dataset", this.genders);

        }
     },

 
  computed: {
    gendersAllData() {      
      return this.gendersData;
    },  
  }
  
};
</script>

