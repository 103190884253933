import Vuelidate from "vuelidate";

import Vue from "vue";

import Vuesax from "vuesax";

import { recaptchaInit } from "../js/recaptcha.js";

import Errors from "./ErrorHandler";

window.moment = require("moment");

Vue.use(Vuelidate);

Vue.use(Vuesax);

window.intlTelInput = require("../js/dial.min");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

window.axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

window.axios.defaults.headers.common["X-CSRF-TOKEN"] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");

window.Pup = require("sweetalert2");

window.Toast = Pup.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: toast => {
        toast.addEventListener("mouseenter", Pup.stopTimer);
        toast.addEventListener("mouseleave", Pup.resumeTimer);
    }
});

import DoctorsComponent from "./vue/DoctorsComponent.vue";

Vue.component(
    "doctors-component",
    require("./vue/DoctorsComponent.vue").default
);

if (document.getElementById("expert_doctors")) {
    new Vue({
        name: "ExpertDoctorsRoot",

        el: "#expert_doctors",

        components: {
            DoctorsComponent
        },

        props: ["doctorsData"],

        data: {
            doctor: ""
        },

        watch: {
            doctor: function(nd, od) {
                let vi = this;
                vi.findDoctor();
            }
        },

        mounted() {},

        methods: {
            findDoctor() {
                let vi = this;
                vi.$refs.docComp.refreshDoctors(vi.doctor);
            }
        }
    });
}

import QuickEnquiryComponent from "./vue/QuickEnquiryComponent.vue";

Vue.component(
    "quick-enquiry",
    require("./vue/QuickEnquiryComponent.vue").default
);

if (document.getElementById("quick-enquiry")) {
    new Vue({
        name: "QuickEnquiryRoot",

        el: "#quick-enquiry",

        components: {
            QuickEnquiryComponent
        },

        props: [],

        data: {},

        mounted() {},

        methods: {}
    });
}

if (document.getElementById("contact-us")) {

    new Vue({
        name: "ContactUsRoot",

        el: "#contact-us",

        data: {
            contactType: "enquiry",

            types: [],

            name: "",

            phone: "",

            phoneWithCC: "",

            dialCode: "91",

            intlPD: null,

            errors: new Errors(),

            currentCountryCode: localStorage.getItem("currentCountryCode") ?? "IN",
        },

        mounted() {
            let vi = this;

            document
                .querySelectorAll('input[name="contact-type"]')
                .forEach(inp => vi.types.push(inp.value));

            vi.initTelInput();
        },

        updated() {
            let vi = this;
            vi.dialCode = vi.intlPD.getSelectedCountryData().dialCode;
        },

        /* watch: {
                contactType: function () {


                    let vi = this;

                    vi.intlPD.destroy();

                    vi.$nextTick(() => {

                        vi.initTelInput();
                    });
                }
            }, */

        computed: {
            enquiryType() {
                let vi = this;
                return vi.contactType === vi.types[0];
            },

            feedbackType() {
                let vi = this;

                return vi.contactType === vi.types[1];
            },

            complaintType() {
                let vi = this;

                return vi.contactType === vi.types[2];
            },

            othersType() {
                let vi = this;

                return vi.contactType === vi.types[3];
            }
        },

        methods: {
            initTelInput() {
                let vi = this;

                let teleDial = vi.$refs.teleDial;

                const cotactEnquiryCountry = document.getElementById("contact-enquiry-country");


                vi.intlPD = intlTelInput(teleDial, {
                    initialCountry: vi.currentCountryCode,
                    separateDialCode: true
                });
                // teleDial.setAttribute(
                //     "dial",
                //     `+${vi.intlPD.getSelectedCountryData().dialCode}`
                //     );

                console.log("teleDial===",vi.intlPD.getSelectedCountryData().name);
                cotactEnquiryCountry.setAttribute('value', vi.intlPD.getSelectedCountryData().name);
                teleDial.setAttribute("dial", `+${vi.intlPD.getSelectedCountryData().dialCode}`);
                teleDial.addEventListener("countrychange", function(e) {
                    teleDial.setAttribute(
                        "dial",
                        `+${vi.intlPD.getSelectedCountryData().dialCode}`
                    );
                    if (teleDial.getAttribute("dial") === "+91") {
                        if (teleDial.value.length >= 10) {
                            teleDial.value = teleDial.value.substring(0, 10);
                        }
                    }
                    cotactEnquiryCountry.setAttribute('value', vi.intlPD.getSelectedCountryData().name);
                });

                vi.dialCode = vi.intlPD.getSelectedCountryData().dialCode;


                vi.phoneWithCC =
                    "+" +
                    vi.intlPD.getSelectedCountryData().dialCode +
                    vi.phone;

                teleDial.addEventListener(
                    "countrychange",

                    function(e) {
                        teleDial.setAttribute(
                            "dial",
                            `+${vi.intlPD.getSelectedCountryData().dialCode}`
                        );

                        if (teleDial.getAttribute("dial") === "+91") {
                            if (teleDial.value.length >= 10) {
                                teleDial.value = teleDial.value.substring(
                                    0,
                                    10
                                );
                            }
                        }


                    }.bind(this)
                );


            },



            onEmailKeyDown(e) {
                if (e.target.value.length > 4) {
                    e.target.classList.contains("is-invalid") ?
                        e.target.classList.remove("is-invalid") :
                        "";
                }
                if (!/^[a-z0-9._%+-@]$/.test(e.key)) e.preventDefault();
            },
            onMaxIDKeyDown(e) {
                if (!/^[A-Za-z0-9_-\s]$/.test(e.key)) e.preventDefault();
            },
            onKeyDown(e) {
                if (!/^[A-Za-z\s]$/.test(e.key)) e.preventDefault();
            },
            phoneOnKeyDown(e) {
                if (!/^[0-9]$/.test(e.key)) e.preventDefault();

                const inp = e.currentTarget;
                if (inp.getAttribute("dial") === "+91") {
                    if (e.target.value.length === 10) {
                        e.target.classList.contains("is-invalid") ?
                            e.target.classList.remove("is-invalid") :
                            "";
                    }
                    if (e.target.value.length > 9) e.preventDefault();
                } else {
                    if (
                        e.target.value.length >= 10 &&
                        e.target.value.length <= 14
                    ) {
                        e.target.classList.contains("is-invalid") ?
                            e.target.classList.remove("is-invalid") :
                            "";
                    }

                    if (e.target.value.length > 13) e.preventDefault();
                }
            },



            async contactSubmit(e) {
                grecaptcha.ready(async() => {
                    let vi = this;
                    let form = vi.$refs.contactUs;
                    let dataPressPhone = vi.$refs.teleDial;
                    // let dataPressEmail = form.querySelector(
                    //     "input[data-press-email]"
                    // );
                    let dataTimeToCall = form.querySelector(
                        'select[name="time-to-call"]'
                    );

                    // if (!(dataPressEmail.value.length > 0)) {
                    //     Toast.fire({
                    //         icon: "warning",
                    //         title: "Please provide a valid email!"
                    //     });
                    //     try {
                    //         dataPressEmail.focus();
                    //     } catch (e) {}
                    //     dataPressEmail.classList.add("is-invalid");
                    //     return false;
                    // }


                    let phone_dial = '';
                    if(dataPressPhone.hasAttribute("dial")) {
                        phone_dial = dataPressPhone.getAttribute("dial").trim();
                    }
        
                    if (phone_dial == '+91' && dataPressPhone.value.length != 10) {
                        Toast.fire({
                            icon: "warning",
                            title: "Please provide a valid number!"
                        });
                        dataPressPhone.focus();
                        dataPressPhone.classList.add("is-invalid");
                        return false;
                    }else {
                        dataPressPhone.classList.remove("is-invalid");
                    }
        


               
                    if (
                        dataPressPhone.value.length < 5 ||
                        dataPressPhone.value.length > 14 ||
                        dataPressPhone.value.charAt(0) === '0'
                    ) {
                        Toast.fire({
                            icon: "warning",
                            title: "Please provide a valid number!"
                        });
                        try {
                            dataPressPhone.focus();
                        } catch (e) {}
                        dataPressPhone.classList.add("is-invalid");
                        return false;
                    }

                    if (dataTimeToCall && !(dataTimeToCall.value.length > 0)) {
                        Toast.fire({
                            icon: "warning",
                            title: "Please provide a preferred time to talk!"
                        });
                        dataTimeToCall.classList.add("is-invalid");
                        return false;
                    }

                    if (form.checkValidity()) {
                        form.querySelectorAll("[name],button").forEach(
                            p => (p.readOnly = !p.readOnly)
                        );
                        if (dataPressPhone.hasAttribute("dial")) {
                            dataPressPhone.value =
                                // dataPressPhone.getAttribute("dial").trim() +
                                dataPressPhone.value.trim();
                        }

                        let formData = $(form).serializeArray();

                        formData = formData.filter((fd, idx) =>
                            fd.value.trim()
                        );

                        const recaptcha = await recaptchaInit("contact_us");

                        formData.push({
                            name: "recaptcha",
                            value: recaptcha
                        });

                        form.querySelectorAll("[name],button").forEach(
                            p => (p.disabled = !p.disabled)
                        );

                        $.ajaxSetup({
                            headers: {
                                "X-CSRF-TOKEN": $(
                                    'meta[name="csrf-token"]'
                                ).attr("content")
                            }
                        });


                        if (formData[0].value == "feedback") {
                            dataLayer.push({
                                'event': 'Mhc_contact_us_compliments_submit'
                            });
                        } else if (formData[0].value == "enquiry") {
                            dataLayer.push({
                                'event': 'Mhc_contact_us_enquiry_submit'
                            });
                        } else if (formData[0].value == "complaint") {
                            dataLayer.push({
                                'event': 'Mhc_contact_us_complaints_submit'
                            });
                        } else if (formData[0].value == "others") {
                            dataLayer.push({
                                'event': 'Mhc_contact_us_others_submit'
                            });
                        }


                        $.ajax({
                                url: form.getAttribute("action"),
                                type: "post",
                                data: formData,
                                dataType: "json"
                            })
                            .done(function(response) {
                                if (response.status === "success") {
                                    $(form).html(
                                        "<div class='text-center f-18'>" +
                                        "Thank you for the submission. We will get back to you soon." +
                                        "</div>"
                                    );
                                    $(form)
                                        .parent()
                                        .addClass("contacUsMsg");
                                    document
                                        .querySelector(
                                            ".announcement-section-panel"
                                        )
                                        .classList.add("contacUsHeight");

                                    setTimeout(() => {
                                        window.location.href =
                                            window.location.origin +
                                            "/contact-us-thanks";
                                    }, 2500);
                                } else if (response.status === "error") {
                                    vi.errors.record(response.errors);

                                    if (dataPressPhone.hasAttribute("dial")) {
                                        dataPressPhone.value = dataPressPhone.value
                                            .trim()
                                            .replace(
                                                dataPressPhone
                                                .getAttribute("dial")
                                                .trim(),
                                                ""
                                            );
                                    }

                                    form.querySelectorAll(
                                        "[name],button"
                                    ).forEach(p => (p.readOnly = !p.readOnly));

                                    form.querySelectorAll(
                                        "[name],button"
                                    ).forEach(p => (p.disabled = !p.disabled));
                                } else {
                                    $(form).append(
                                        "<div class='text-center f-18'>" +
                                        "Submission failed. Please try after sometime." +
                                        "</div>"
                                    );
                                    setTimeout(() => {
                                        window.location.href =
                                            window.location.origin +
                                            "/contact-us";
                                    }, 2500);
                                }
                            })
                            .fail(function(error) {
                                console.log("error", error);
                                $(form).append(
                                    "<div class='text-center f-18'>" +
                                    "Server Error Occurred. Please try after sometime." +
                                    "</div>"
                                );
                                form.querySelectorAll("[name],button").forEach(
                                    p => (p.disabled = !p.disabled)
                                );
                            });
                    } else {
                        let tmpSubmit = document.createElement("button");
                        form.appendChild(tmpSubmit);
                        tmpSubmit.click();
                        form.removeChild(tmpSubmit);
                    }
                });
            }
        }
    });
}

if (document.getElementById("article-contact")) {
    new Vue({
        name: "ArticleContactFormRoot",

        el: "#article-contact",

        data: {
            name: "",

            phone: "",

            phoneWithCC: "",

            dialCode: "91",

            intlPD: null,

            errors: new Errors(),

            currentCountryCode: localStorage.getItem("currentCountryCode") ?? "IN",
        },

        mounted() {
            let vi = this;
            vi.initTelInput();
        },

        updated() {
            let vi = this;
            vi.dialCode = vi.intlPD.getSelectedCountryData().dialCode;
        },

        methods: {
            initTelInput() {
                let vi = this;

                let teleDial = vi.$refs.teleDial;

                vi.intlPD = intlTelInput(teleDial, {
                    initialCountry: vi.currentCountryCode,
                    separateDialCode: true
                });

                teleDial.setAttribute(
                    "dial",
                    `+${vi.intlPD.getSelectedCountryData().dialCode}`
                );

                vi.dialCode = vi.intlPD.getSelectedCountryData().dialCode;

                vi.phoneWithCC =
                    "+" +
                    vi.intlPD.getSelectedCountryData().dialCode +
                    vi.phone;

                teleDial.addEventListener(
                    "countrychange",

                    function(e) {
                        teleDial.setAttribute(
                            "dial",
                            `+${vi.intlPD.getSelectedCountryData().dialCode}`
                        );

                        if (teleDial.getAttribute("dial") === "+91") {
                            if (teleDial.value.length >= 10) {
                                teleDial.value = teleDial.value.substring(
                                    0,
                                    10
                                );
                            }
                        }
                    }.bind(this)
                );
            },
            onEmailKeyDown(e) {
                if (e.target.value.length > 4) {
                    e.target.classList.contains("is-invalid") ?
                        e.target.classList.remove("is-invalid") :
                        "";
                }
                if (!/^[a-z0-9._%+-@]$/.test(e.key)) e.preventDefault();
            },
            onKeyDown(e) {
                if (!/^[A-Za-z\s]$/.test(e.key)) e.preventDefault();
            },
            phoneOnKeyDown(e) {
                if (!/^[0-9]$/.test(e.key)) e.preventDefault();

                const inp = e.currentTarget;
                if (inp.getAttribute("dial") === "+91") {
                    if (e.target.value.length === 10) {
                        e.target.classList.contains("is-invalid") ?
                            e.target.classList.remove("is-invalid") :
                            "";
                    }
                    if (e.target.value.length > 9) e.preventDefault();
                } else {
                    if (
                        e.target.value.length >= 10 &&
                        e.target.value.length <= 14
                    ) {
                        e.target.classList.contains("is-invalid") ?
                            e.target.classList.remove("is-invalid") :
                            "";
                    }

                    if (e.target.value.length > 13) e.preventDefault();
                }
            },

            async contactSubmit(e) {
                console.log('click coming from component js');
                grecaptcha.ready(async() => {
                    let vi = this;
                    let form = vi.$refs.articleForm;
                    let dataPressPhone = vi.$refs.teleDial;
                    let dataPressEmail = form.querySelector(
                        "input[data-press-email]"
                    );
                    let dataPressName = form.querySelector(
                        "input[data-press-name]"
                    );

                    if (!(dataPressName.value.length > 0)) {
                        Toast.fire({
                            icon: "warning",
                            title: "Please provide a valid name!"
                        });
                        try {
                            dataPressName.focus();
                        } catch (e) {}
                        dataPressName.classList.add("is-invalid");
                        return false;
                    }

                    if (!(dataPressEmail.value.length > 0)) {
                        Toast.fire({
                            icon: "warning",
                            title: "Please provide a valid email!"
                        });
                        try {
                            dataPressEmail.focus();
                        } catch (e) {}
                        dataPressEmail.classList.add("is-invalid");
                        return false;
                    }

                    let phone_dial = '';
                    if(dataPressPhone.hasAttribute("dial")) {
                        phone_dial = dataPressPhone.getAttribute("dial").trim();
                    }
        
                    if (phone_dial == '+91' && dataPressPhone.value.length != 10) {
                        Toast.fire({
                            icon: "warning",
                            title: "Please provide a valid number!"
                        });
                        dataPressPhone.focus();
                        dataPressPhone.classList.add("is-invalid");
                        return false;
                    }else {
                        dataPressPhone.classList.remove("is-invalid");
                    }
        
                    if (
                        dataPressPhone.value.length < 5 ||
                        dataPressPhone.value.length > 14 ||
                        dataPressPhone.value.charAt(0) === '0'
                    ) {
                        Toast.fire({
                            icon: "warning",
                            title: "Please provide a valid number!"
                        });
                        try {
                            dataPressPhone.focus();
                        } catch (e) {}
                        dataPressPhone.classList.add("is-invalid");
                        return false;
                    }

                    if (form.checkValidity()) {
                        form.querySelectorAll("[name],button").forEach(
                            p => (p.readOnly = !p.readOnly)
                        );
                        if (dataPressPhone.hasAttribute("dial")) {
                            dataPressPhone.value =
                                dataPressPhone.getAttribute("dial").trim() +
                                dataPressPhone.value.trim();
                        }

                        let formData = $(form).serializeArray();

                        formData = formData.filter((fd, idx) =>
                            fd.value.trim()
                        );

                        form.querySelectorAll("[name],button").forEach(
                            p => (p.disabled = !p.disabled)
                        );

                        $.ajaxSetup({
                            headers: {
                                "X-CSRF-TOKEN": $(
                                    'meta[name="csrf-token"]'
                                ).attr("content")
                            }
                        });


                        $.ajax({
                                url: form.getAttribute("action"),
                                type: "post",
                                data: formData,
                                dataType: "json"
                            })
                            .done(function(response) {
                                if (response.status === "success") {
                                    $(form).html(
                                        "<div class='text-center f-18'>" +
                                        "Thank you for the submission. We will get back to you soon." +
                                        "</div>"
                                    );

                                    dataLayer.push({
                                        'event': 'mmj_contact_us_form_submit'
                                        });


                                } else if (response.status === "error") {
                                    vi.errors.record(response.errors);

                                    if (dataPressPhone.hasAttribute("dial")) {
                                        dataPressPhone.value = dataPressPhone.value
                                            .trim()
                                            .replace(
                                                dataPressPhone
                                                .getAttribute("dial")
                                                .trim(),
                                                ""
                                            );
                                    }

                                    form.querySelectorAll(
                                        "[name],button"
                                    ).forEach(p => (p.readOnly = !p.readOnly));

                                    form.querySelectorAll(
                                        "[name],button"
                                    ).forEach(p => (p.disabled = !p.disabled));
                                } else {
                                    $(form).append(
                                        "<div class='text-center f-18'>" +
                                        "Submission failed. Please try after sometime." +
                                        "</div>"
                                    );
                                }
                            })
                            .fail(function(error) {
                                console.log("error", error);
                                $(form).append(
                                    "<div class='text-center f-18'>" +
                                    "Server Error Occurred. Please try after sometime." +
                                    "</div>"
                                );
                                form.querySelectorAll("[name],button").forEach(
                                    p => (p.disabled = !p.disabled)
                                );
                            });
                    } else {
                        let tmpSubmit = document.createElement("button");
                        form.appendChild(tmpSubmit);
                        tmpSubmit.click();
                        form.removeChild(tmpSubmit);
                    }
                });
            }
        }
    });
}

import JobApplicationComponent from "./vue/JobApplicationComponent.vue";

Vue.component(
    "job-application",
    require("./vue/JobApplicationComponent.vue").default
);
if (document.getElementById("job-application")) {
    new Vue({
        name: "JobApplicationRoot",
        el: "#job-application",
        components: {
            JobApplicationComponent
        },
        props: [],
        data: {},
        mounted() {},
        methods: {}
    });
}



import EducationEnquiry from "./vue/EducationEnquiry.vue";

Vue.component("education-enquiry", require("./vue/EducationEnquiry.vue").default);
if (document.getElementById("education-enquiry")) {
    new Vue({
        name: "EducationEnquiryRoot",
        el: "#education-enquiry",
        components: {
            EducationEnquiry
        },
        props: [],
        data: {},
        mounted() {},
        methods: {}
    });
}


import AcademicEnquiry from "./vue/AcademicEnquiry.vue";

Vue.component("academic-enquiry", require("./vue/AcademicEnquiry.vue").default);
if (document.getElementById("academic-enquiry")) {
    new Vue({
        name: "AcademicEnquiryRoot",
        el: "#academic-enquiry",
        components: {
            AcademicEnquiry
        },
        props: [],
        data: {},
        mounted() {},
        methods: {}
    });
}

import HospitalComponent from "./vue/HospitalComponent.vue";
import AgeComponent from "./vue/AgeComponent.vue";
import GenderComponent from "./vue/GenderComponent.vue";
import DiseaseComponent from "./vue/DiseaseComponent.vue";
import {
    required,
    minLength,
    maxLength,
    email,
    integer,
    alpha,
    alphaNum,
    helpers
} from "vuelidate/lib/validators";
const digitsOnly = helpers.regex("alphaSpace", /^[0-9]*$/);
Vue.component(
    "hospital-component",
    require("./vue/HospitalComponent.vue").default,
    "age-component",
    require("./vue/AgeComponent.vue"),
    "gender-component",
    require("./vue/GenderComponent.vue"),
    "disease-component",
    require("./vue/DiseaseComponent.vue"),
);




if (document.getElementById("immigration-main")) {
    const alphaSpace = helpers.regex("alphaSpace", /^[a-zA-Z\s]*$/);


    //const checkDOB = (this) => !helpers.req(value) || value.includes('cool')


    new Vue({
        name: "ImmigartionMainRoot",
        el: "#immigration-main",
        data: {
            visibleStep:'1',
                preview:false,
                center:'',
                country:'',
                age:'',
                package:'',
                name:'',
                surname:'',
                email:'',
                careof:'',
                fHName:'',
                phoneNumber:'',
                alternatePhoneNumber:'',
                dob:'',
                maritalStatus:'',
                gender:'',
                passportNumber:'',
                passportIssue:'',
                passportExpiry:'',
                address:'',
                city:'',
                state:'',
                zip:'',
                feverStatus:'0',
                coughStatus:'0',
                throatStatus:'0',
                runnyNoseStatus:'0',
                vomitingStatus:'0',
                diarrhoeaStatus:'0',
                appointmentDate:'',
                appointmentTimeSlot:'',
                appointmentTimeSlots:[],
                appointmentRawTimeSlots:[],
                submitFlag:true,
                countries:[],
                packages:[],
                terms:false,
                passportCheck:false,
                mohaliSuccess:false,
                fee:'',
                processing:false,
                html:'',
                loading:false,
                salutation:'',
                privacyPolicy:'',
                ageGroupData:[],
                vaccination:'',
                dose1:'',
                dose2:'',
                dose3:'',
                dose4:'',
        },

        validations: {

                name: {
                    required: required,
                    alpha: alphaSpace
                },
                surname: {
                    required: required,
                    alpha: alphaSpace
                },
                email: {
                    required: required,
                    email: email
                },
                phoneNumber: {
                    required: required,
                    digit: digitsOnly,
                    number: integer,
                    minLength: minLength(10),
                    maxLength: maxLength(10)
                },
                alternatePhoneNumber: {
                    digit: digitsOnly,
                    number: integer,
                    minLength: minLength(10),
                    maxLength: maxLength(10)
                },
                fHName:{
                    required: required,
                    alpha: alphaSpace
                },
                zip: {
                    required: required,
                    digit: digitsOnly,
                    number: integer,
                    minLength: minLength(6),
                    maxLength: maxLength(6)
                },
                address: {
                    required: required
                },
                city: {
                    required: required,
                    alpha: alphaSpace
                },
                state: {
                    required: required,
                    alpha: alphaSpace
                },
                passportNumber:{
                    required: required,
                    alphaNum: alphaNum,
                    minLength: minLength(8),
                    maxLength: maxLength(12)
                },
                careof:{
                    required: required
                },
                dob:{
                    required: required
                },
                maritalStatus:{
                    required: required
                },
                gender:{
                    required: required
                },
                vaccination:{
                    required: required
                },

                passportIssue:{
                    required: required,
                    checkDOB() {
                        let vi = this;
                        if (this.dob) {
                            if (this.dob < this.passportIssue ) {
                                return true;
                              } else {
                                return false;
                              }
                        }else{
                            return false;
                        }
                      },
                },
                passportExpiry:{
                    required: required,
                    checkPassportIssueDate() {
                        let vi = this;
                        if (this.passportIssue) {
                            if (this.passportIssue < this.passportExpiry ) {
                                return true;
                              } else {
                                return false;
                              }
                        }else{
                            return false;
                        }
                      },
                },

                salutation:{
                    required: required,
                },
                dose1:{
                    checkVaccinationDate() {
                        let vi = this;
                        if (this.dose1) {
                            if (this.dose1 > '2021-01-01' ) {
                                return true;
                              } else {
                                return false;
                              }
                        }else{
                            return true;
                        }
                      },
                },
                dose2:{
                    checkDose1() {
                        let vi = this;
                        if(this.dose2){
                            if (this.dose1) {
                                if (this.dose1 < this.dose2 ) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }else{
                                return false;
                            }
                        }else{
                            return true;
                        }
                      },
                },
                dose3:{
                    checkDose2() {
                        let vi = this;
                        if(this.dose3){
                        if (this.dose2) {
                            if (this.dose2 < this.dose3 ) {
                                return true;
                              } else {
                                return false;
                              }
                        }else{
                            return false;
                        }
                    }else{
                        return true;
                    }
                      },
                },
                dose4:{
                    checkDose3() {
                        let vi = this;
                        if(this.dose4){
                        if (this.dose3) {
                            if (this.dose3 < this.dose4 ) {
                                return true;
                              } else {
                                return false;
                              }
                        }else{
                            return false;
                        }
                    }else{
                        return true;
                    }
                      },
                },





        },
        mounted() {
        },


        computed: {

        },

        filters: {
            checkNumaric: function (value) {
              if (!value) return ''
              value = value.toString()

              if( value.charAt(0) == 1 || value.charAt(0) == 2 || value.charAt(0) == 3 || value.charAt(0) == 4 || value.charAt(0) == 5 || value.charAt(0) == 6 || value.charAt(0) == 7 || value.charAt(0) == 8 || value.charAt(0) == 9 || value.charAt(0) == 0){
                return value + ' Years';
              }else{
                return value;
              }

            }
          },
        methods: {




            showStepOne: function (e) {
                this.visibleStep = '1';
                window.scrollTo(0,0);
            },
            showStepTwo: function (e) {
                this.countries =[];
                this.loading = true;


                dataLayer.push({
                    'event': 'immigration_select_centre',
                    'immigration_selected_centre': this.center
                    });



                if(this.center) {
                    axios.get('/immigration/get-country/' + this.center).then(response => {
                        //console.log("pp ploicy",response.data.privacyPolicy.original);
                        this.countries = response.data.country.original;
                        this.privacyPolicy = response.data.privacyPolicy.original;
                        this.loading=false;
                    })
                } else {
                    this.countries = [];
                    this.loading = false;
                }
                this.visibleStep = '2';

                window.scrollTo(0,0);
            },
            showStepThree: function (e) {

                this.ageGroupData = [];


                dataLayer.push({
                    'event': 'immigration_select_country',
                    'immigration_selected_country': this.country
                    });


                if(this.center && this.country) {
                    axios.get('/immigration/get-age-group-data/' + this.center+'/'+this.country).then(response => {

                        this.ageGroupData = response.data.age.original;
                    })
                } else {
                    this.ageGroupData = [];
                }


                this.visibleStep = '3';
                $('.error').text('');
                window.scrollTo(0,0);
            },
            showStepFour: function (e) {
                this.visibleStep = '4';
                window.scrollTo(0,0);

                dataLayer.push({
                    'event': 'immigration_age_package',
                    'immigration_package': this.package
                    });


            },
            editShowStepFive: function(e){
                dataLayer.push({
                    'event': 'immigration_edit_click'
                    });


                    this.submitFlag = true;

                let vi = this;

                vi.$v.name.$touch();
                vi.$v.surname.$touch();
                vi.$v.email.$touch();
                vi.$v.careof.$touch();
                vi.$v.fHName.$touch();
                vi.$v.phoneNumber.$touch();
                vi.$v.alternatePhoneNumber.$touch();
                vi.$v.dob.$touch();
                vi.$v.maritalStatus.$touch();
                vi.$v.gender.$touch();
                vi.$v.vaccination.$touch();
                vi.$v.passportNumber.$touch();
                vi.$v.passportIssue.$touch();
                vi.$v.passportExpiry.$touch();
                vi.$v.address.$touch();
                vi.$v.city.$touch();
                vi.$v.state.$touch();
                vi.$v.zip.$touch();
                vi.$v.salutation.$touch();
                vi.$v.dose1.$touch();
                vi.$v.dose2.$touch();
                vi.$v.dose3.$touch();
                vi.$v.dose4.$touch();


                if(vi.$v.name.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.surname.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.email.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.careof.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.fHName.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.phoneNumber.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.alternatePhoneNumber.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.maritalStatus.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.gender.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.vaccination.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.passportNumber.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.passportIssue.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.passportExpiry.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.address.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.city.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.state.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.zip.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.salutation.$error ){
                    this.submitFlag = false;
                }

                if(vi.$v.dose1.$error ){
                    this.submitFlag = false;
                }

                if(vi.$v.dose2.$error ){
                    this.submitFlag = false;
                }

                if(vi.$v.dose3.$error ){
                    this.submitFlag = false;
                }

                if(vi.$v.dose4.$error ){
                    this.submitFlag = false;
                }

                if(!this.submitFlag){
                    return false;
                }


                this.visibleStep = '5';
                this.preview = false;
                window.scrollTo(0,0);

            },

            showStepFive: function (e) {
                this.submitFlag = true;

                let vi = this;

                vi.$v.name.$touch();
                vi.$v.surname.$touch();
                vi.$v.email.$touch();
                vi.$v.careof.$touch();
                vi.$v.fHName.$touch();
                vi.$v.phoneNumber.$touch();
                vi.$v.alternatePhoneNumber.$touch();
                vi.$v.dob.$touch();
                vi.$v.maritalStatus.$touch();
                vi.$v.gender.$touch();
                vi.$v.vaccination.$touch();
                vi.$v.passportNumber.$touch();
                vi.$v.passportIssue.$touch();
                vi.$v.passportExpiry.$touch();
                vi.$v.address.$touch();
                vi.$v.city.$touch();
                vi.$v.state.$touch();
                vi.$v.zip.$touch();
                vi.$v.salutation.$touch();
                vi.$v.dose1.$touch();
                vi.$v.dose2.$touch();
                vi.$v.dose3.$touch();
                vi.$v.dose4.$touch();


                if(vi.$v.name.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.surname.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.email.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.careof.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.fHName.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.phoneNumber.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.alternatePhoneNumber.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.maritalStatus.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.gender.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.vaccination.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.passportNumber.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.passportIssue.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.passportExpiry.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.address.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.city.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.state.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.zip.$error ){
                    this.submitFlag = false;
                }
                if(vi.$v.salutation.$error ){
                    this.submitFlag = false;
                }

                if(vi.$v.dose1.$error ){
                    this.submitFlag = false;
                }

                if(vi.$v.dose2.$error ){
                    this.submitFlag = false;
                }

                if(vi.$v.dose3.$error ){
                    this.submitFlag = false;
                }

                if(vi.$v.dose4.$error ){
                    this.submitFlag = false;
                }


                // if(!this.feverStatus){
                //     $('.error-fever-status').text('This is a required field');
                //     window.scrollTo(0,0);
                //     this.submitFlag = false;
                // }else{
                //     $('.error-fever-status').text('');
                // }

                // if(!this.coughStatus){
                //     $('.error-cough-status').text('This is a required field');
                //     window.scrollTo(0,0);
                //     this.submitFlag = false;
                // }else{
                //     $('.error-cough-status').text('');
                // }

                // if(!this.throatStatus){
                //     $('.error-throat-status').text('This is a required field');
                //     window.scrollTo(0,0);
                //     this.submitFlag = false;
                // }else{
                //     $('.error-throat-status').text('');
                // }

                // if(!this.runnyNoseStatus){
                //     $('.error-runny-nose-status').text('This is a required field');
                //     window.scrollTo(0,0);
                //     this.submitFlag = false;
                // }else{
                //     $('.error-runny-nose-status').text('');
                // }

                // if(!this.vomitingStatus){
                //     $('.error-vomiting-status').text('This is a required field');
                //     window.scrollTo(0,0);
                //     this.submitFlag = false;
                // }else{
                //     $('.error-vomiting-status').text('');
                // }

                // if(!this.diarrhoeaStatus){
                //     $('.error-diarrhoea-status').text('This is a required field');
                //     window.scrollTo(0,0);
                //     this.submitFlag = false;
                // }else{
                //     $('.error-diarrhoea-status').text('');
                // }


                if(!this.submitFlag){
                    return false;
                }


                this.visibleStep = '5';
                this.preview = false;
                window.scrollTo(0,0);
            },
            saveRequest: function (e) {




                this.loading = false;

                let fd = new FormData();
                fd.append('country', this.country);
                fd.append('center', this.center);
                fd.append('package', this.package);
                fd.append('ageGroup', this.age);
                fd.append('salutation', this.salutation);
                fd.append('name', this.name);
                fd.append('surname', this.surname);
                fd.append('email', this.email);
                fd.append('careof', this.careof);
                fd.append('phone', this.phoneNumber);
                fd.append('aphone', this.alternatePhoneNumber);
                fd.append('fHName', this.fHName);
                fd.append('dob', this.dob);
                fd.append('gender', this.gender);
                fd.append('maritalStatus', this.maritalStatus);
                fd.append('passportNumber', this.passportNumber);
                fd.append('passportIssue', this.passportIssue);
                fd.append('passportExpiry', this.passportExpiry);
                fd.append('address', this.address);
                fd.append('city', this.city);
                fd.append('state', this.state);
                fd.append('zip', this.zip);
                fd.append('feverStatus', this.feverStatus);
                fd.append('coughStatus', this.coughStatus);
                fd.append('throatStatus', this.throatStatus);
                fd.append('runnyNoseStatus', this.runnyNoseStatus);
                fd.append('vomitingStatus', this.vomitingStatus);
                fd.append('diarrhoeaStatus', this.diarrhoeaStatus);
                fd.append('appointmentDate', this.appointmentDate);
                fd.append('appointmentTimeSlot', this.appointmentTimeSlot);
                fd.append('fee', this.fee);
                fd.append('appointmentTime', this.appointmentTimeSlot);
                fd.append('vaccination', this.vaccination);
                fd.append('dose1', this.dose1);
                fd.append('dose2', this.dose2);
                fd.append('dose3', this.dose3);
                fd.append('dose4', this.dose4);
                fd.append('appointmentDate', this.appointmentDate);
                fd.append('procedureId', this.appointmentRawTimeSlots[0].procedures[0].id);
                fd.append('slotId', this.appointmentRawTimeSlots[0].slot_id);

                console.log('procedureId', this.appointmentRawTimeSlots[0].procedures[0].id);
                console.log('slotId', this.appointmentRawTimeSlots[0].slot_id);

                this.submitFlag = true;

                if(!this.terms){
                    $('.error-terms').text('This is a required field');
                    window.scrollTo(0,0);
                    this.submitFlag = false;
                }else{
                    $('.error-terms').text('');
                }


                if(!this.passportCheck){
                    $('.error-passport-check').text('This is a required field');
                    window.scrollTo(0,0);
                    this.submitFlag = false;
                }else{
                    $('.error-passport-check').text('');
                }


                if(!this.submitFlag){
                    return false;
                }

                this.loading = true;


                dataLayer.push({
                    'event': 'immigration_book_appointment',
                    'immigration_appointment_fee': this.fee
                    });



                //save data to db api

                    axios.post('/immigration/ajax_create', fd).then(response => {
                        if(response.data.success){

                            if(this.center == 'max-med-center-mohali'){
                                this.mohaliSuccess = true;
                                this.loading = false;
                            }else{
                                $(response.data.html).appendTo('body').submit();
                            }
                        }else{

                            if(this.center == 'max-med-center-mohali'){
                                this.mohaliSuccess = true;
                                this.loading = false;
                            }else{
                                this.loading = false;
                            }



                        }

                    })


            },
            showPreview: function (e) {
                this.preview = true;
                $('.error').text('');
                window.scrollTo(0,0);


                dataLayer.push({
                    'event': 'immigration_appointment',
                    'immigration_appointmentdate': this.appointmentDate,
                    'immigration_appointmenttime': this.appointmentTimeSlot
                    });

            },

            isDisable(data) {
                return data.length > 0;
            },
            getPackages(event){
                this.packages = [];
                this.package = '';
                this.appointmentDate='';
                this.appointmentTimeSlot='';
                this.appointmentTimeSlots = [];
                this.appointmentRawTimeSlots = [];
                this.loading = true;

                if(this.age) {
                    axios.get('/immigration/get-package/' + this.center +'/'+this.country+'/'+this.age).then(response => {
                        //console.log(response.data.packages.original);
                        this.packages = response.data.packages.original;
                        this.loading = false;
                    })
                } else {
                    this.packages = [];
                    this.loading = false;
                }
            },
            getSlot(event){
                this.appointmentTimeSlot='';
                this.appointmentTimeSlots = [];
                this.appointmentRawTimeSlots = [];
                this.loading = true;

                let singleFilterData  = this.packages.filter(pkg=>pkg.Slug == this.package);
                //console.log("single filter data", singleFilterData);
                    this.fee = singleFilterData[0].ImmigrationFee;

                    if(this.appointmentDate) {
                        axios.get('/immigration/get-slots/' + singleFilterData[0].Practo_doctor_id +'/'+singleFilterData[0].Practo_clinic_id+'/'+this.appointmentDate).then(response => {
                            this.appointmentRawTimeSlots = response.data.slots.original[0].slots;
                            console.log("raw data", this.appointmentRawTimeSlots);
                            console.log("appontment time slot",this.appointmentTimeSlot);
                            if(this.appointmentRawTimeSlots.length > 0){
                                this.appointmentTimeSlots = response.data.slots.original[0].slots[0].appointment_times.filter(slt=>slt.available == true);
                            }else{
                                this.appointmentTimeSlots =[];
                            }
                            this.loading = false;

                        })
                    } else {
                        this.appointmentTimeSlots = [];
                        this.appointmentRawTimeSlots = [];
                        this.loading = false;
                    }
            }

        }
    });
}

if (document.getElementById('international-payment-main')) {
    new Vue({
        name: "InternationalPaymentMainRoot",
        el: "#international-payment-main",
        data: {
            login: {
                mobile: '',
                max_id: '',
                login_type: 'mobile',
                term: '',
                dialCode: '1',
            },
            visibleStep: 1,
            existing_pat_max_id: '',
            char1: '',
            char2: '',
            char3: '',
            char4: '',
            mobileBox: true,
            otpBox: false,
            patientPaymentForm: false,
            existingPatientBox: false,
            patients: [],
            genders: [
                {
                    'id': 1,
                    'Name': 'Male'
                },
                {
                    'id': 2,
                    'Name': 'Female'
                },
                {
                    'id': 3,
                    'Name': 'Other'
                }
            ],
            selectedPatient: [],
            newRegPatient: {
                PatientName: '',
                Title: '',
                FirstName: '',
                LastName: '',
                DateOfBirth: '',
                Gender: '',
                GenderID: 0,
                EmailID: '',
                MobileNo: '',
                Address: '',
                AreaID: 0,
                Area: '',
                CityID: 0,
                CityName: '',
                StateID: 0,
                StateName: '',
                NationalityId: 0,
                Nationality: '',
                MaritalStatus: '',
                MaritalStatusID: 0
            },
            doctors: [],
            errors: {
                mobile: '',
                maxid: '',
                otpError: ''
            },
            isSubmitting: false,
            isLoading: false,
            countries: [],
            states: [],
            cities: [],
            localities: [],
            patient_type: null,
            amount: '',
            deposit_type: '',
            deposit_mode: '',
            HospitalId: '',
            HospitalName: '',
            submitting: false,
            GeoLocationId: '',
            submittingConfirmation: false,
            timerOn: false,
            counter: 1,
            should_login_sometime: false
        },

        validations: {
            newRegPatient: {
                Title: {
                    required: required,
                    maxLength: maxLength(50)
                },

                FirstName: {
                    required: required,
                    maxLength: maxLength(50)
                },

                LastName: {
                    required: required,
                    maxLength: maxLength(30)
                },

                MobileNo: {
                    required: required,
                    number: integer,
                    minLength: minLength(7),
                    maxLength: maxLength(18)
                },

                EmailID: {
                    required: required,
                    email: email,
                    maxLength: maxLength(50)
                },

                DateOfBirth: {
                    required: required
                },

                Gender: {
                    required: required
                },

                Nationality: {
                    required: required,
                }
            },

            deposit_type: {
                required: required
            },

            HospitalId: {
                required: required
            },

            amount: {
                required: required
            },

            deposit_mode: {
                required: required
            }
        },

        mounted() {

            axios.get('https://maxapp.in/geolocation?Filter=country&ParentId=0').then(response => {
                console.log(response);
                this.countries = response.data.RetValue;
            });

            // intl tel number
            let isMobileBoxActive = sessionStorage.getItem('mobileBox');
            this.mobileBox = !isMobileBoxActive;

            let vi = this;
            let teleDial = vi.$refs.teleDial;

            vi.intlPD = intlTelInput(teleDial, {
                // excludeCountries: ["us"],
                separateDialCode: true,
                preferredCountries: ['mv','lk', 'np'],
                utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js",
            });

            teleDial.setAttribute(
                "dial",
                `+${vi.intlPD.getSelectedCountryData().dialCode}`
            );
            vi.login.dialCode = vi.intlPD.getSelectedCountryData().dialCode;
            teleDial.addEventListener(
                "countrychange",
                function(e) {
                    teleDial.setAttribute(
                        "dial", `+${vi.intlPD.getSelectedCountryData().dialCode}`
                    );

                    if (teleDial.getAttribute("dial") === "+91") {
                        if (vi.login.term.length >= 10) {
                            vi.login.term = vi.login.term.substring(0, 10);
                        }
                    }
                    vi.login.dialCode = vi.intlPD.getSelectedCountryData().dialCode;
                    //vi.login.phoneWithCC = `+${vi.login.dialCode}${vi.login.term}`;
                }.bind(this)
            );

            this.checkCookie('otp_enable_after');
        },

        methods: {
            formTouch() {
                let vi = this;
                vi.$v.newRegPatient.$touch();
            },

            formTouchConfirmation() {
                let vi = this;
                vi.$v.deposit_type.$touch();
                vi.$v.HospitalId.$touch();
                vi.$v.amount.$touch();
                vi.$v.deposit_mode.$touch();
            },

            onGenderChange(event) {
                if (event.target.options.selectedIndex > 0) {
                    const theTarget = event.target.options[event.target.options.selectedIndex].dataset;
                    console.log(theTarget);
                    this.newRegPatient.GenderID = theTarget.id;
                    this.newRegPatient.Gender = event.target.value;
                }
            },

            onHospitalChange(event) {
                if (event.target.options.selectedIndex > 0) {
                    const theTarget = event.target.options[event.target.options.selectedIndex].dataset;
                    console.log(theTarget);
                    this.HospitalId = theTarget.id;
                    this.HospitalName = event.target.value;
                }
            },

            onMaritalStatusChange(event) {
                if (event.target.options.selectedIndex > 0) {
                    const theTarget = event.target.options[event.target.options.selectedIndex].dataset;
                    console.log(theTarget);
                    this.newRegPatient.MaritalStatusID = theTarget.id;
                    this.newRegPatient.MaritalStatus = event.target.value;
                }
            },

            onLocalityChange(event) {
                if (event.target.options.selectedIndex > 0) {
                    const theTarget = event.target.options[event.target.options.selectedIndex].dataset;
                    this.newRegPatient.AreaID = theTarget.id;
                    this.newRegPatient.Area = event.target.value;
                }
            },

            getState: function(event) {
                let countryId = event.target.options[event.target.options.selectedIndex].dataset.id;
                this.newRegPatient.NationalityId= countryId;
                this.GeoLocationId = countryId;
                this.newRegPatient.Nationality = event.target.value;
                axios.get('https://maxapp.in/geolocation?Filter=state&ParentId='+countryId).then(response => {
                    this.states = response.data.RetValue;
                })
            },

            getCity: function(event) {
                let stateId = event.target.options[event.target.options.selectedIndex].dataset.id;
                axios.get('https://maxapp.in/geolocation?Filter=city&ParentId='+stateId).then(response => {
                    this.cities = response.data.RetValue;
                })
            },

            getLocality: function(event) {
                let cityId = event.target.options[event.target.options.selectedIndex].dataset.id;
                axios.get('https://maxapp.in/geolocation?Filter=locality&ParentId='+cityId).then(response => {
                    this.localities = response.data.RetValue;
                })
            },

            getHospitalDoctor(event) {
                let hospitalSlug = event.target.value;
                this.isLoading = true;
                this.doctors = [];
                if(hospitalSlug) {
                    axios.get('/get-hospital-doctor/' + hospitalSlug).then(response => {
                       console.log("response data", response.data);
                        let ftr= response.data.filter((elem, index)=>
                        response.data.findIndex(obj=>obj.id === elem.id)  === index);
                        this.doctors = ftr.filter((itm)=> (itm['id']!=2689 && itm['id']!=2729 && itm['id']!=2748 && itm['id']!=2777 && itm['id']!=2778 && itm['id']!=2780 && itm['id']!=2781 && itm['id']!=2785 && itm['id']!=2815 && itm['id']!=2820 && itm['id']!=2821 && itm['id']!=2826 && itm['id']!=2835 && itm['id']!=2848 && itm['id']!=2853 && itm['id']!=2859 && itm['id']!=2861) );

                        this.isLoading = false;
                    })
                } else {
                    this.doctors = [];
                    this.isLoading = false;
                }
            },

            onKeyup(event){
                event.target.value = event.target.value.replace(/[^0-9]/g,'');
                if((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)){
                    const next = event.target.nextElementSibling;
                    if(next === null) return
                    event.target.nextElementSibling.focus()
                }

                if(event.keyCode === 8) {
                    const previous = event.target.previousElementSibling;
                    if(previous === null) return
                    event.target.previousElementSibling.focus();
                }
            },

            loginBy(type) {
                this.login.login_type = type;
            },

            async loginPatient(e) {
                //grecaptcha.ready(async() => {
                    // e.preventDefault();

                    if (this.login.login_type === 'mobile' && this.login.term === '') {
                        this.errors.mobile = "Please enter mobile number";
                        return false;
                    }

                    if (this.login.login_type === 'maxid' && this.login.term === '') {
                        this.errors.maxid = "Please enter max id";
                        return false;
                    }

                    let loginApiURL = '';
                    // if (this.login.dialCode === '91') {
                    //     loginApiURL = `https://maxapp.in/international-patient-search?searchtype=${this.login.login_type}&term=${this.login.term}`;
                    // } else {
                    //     loginApiURL = `https://maxapp.in/international-patient-search?searchtype=${this.login.login_type}&term=${this.phoneWithCC}&plainnum=${this.login.term}`;
                    // }

                    loginApiURL = `https://maxapp.in/international-patient-search?searchtype=${this.login.login_type}&term=${this.phoneWithCC}&dialcode=${this.login.dialCode}`;

                    this.isSubmitting = true;

                    // const recaptcha = await recaptchaInit("login");

                    try {
                        axios.get(loginApiURL).then(response => {
                            this.isSubmitting = false;
                            console.log('OTP TRIGGER');
                            console.log(response);
                            if (this.login.login_type === 'maxid') {
                                if(response.data.data.Patients.length) {
                                    this.patients = response.data.data.Patients;
                                    this.existingPatientBox = true;
                                    this.mobileBox = false;
                                } else {
                                    this.patientPaymentForm = true;
                                    this.mobileBox = false;
                                }
                            } else {
                                if(response.data.status === 'accepted') {
                                    this.mobileBox = false;
                                    this.otpBox = true;
                                    this.newRegPatient.MobileNo = this.login.term;
                                    this.timerOn = true;
                                    this.otpCounter(59);
                                }
                            }
                        });
                    } catch (e) {
                        console.log(e);
                    }
                //})
            },

            otpCounter(remaining) {
                var self = this;
                let m = Math.floor(remaining / 60);
                let s = remaining % 60;

                m = m < 10 ? '0' + m : m;
                s = s < 10 ? '0' + s : s;
                $('#otp-counter').text(`Resend OTP in ${m}:${s} seconds`);
                remaining -= 1;

                if(remaining >= 0 && this.timerOn) {
                    setTimeout(function() {
                        self.otpCounter(remaining);
                    }, 1000);
                    return;
                }

                if(!this.timerOn) {
                    // Do validate stuff here
                    console.log('Timer Off');
                    return;
                }

                $('#otp-counter').text('');
                $('#resendLink').show();
            },

            resendOTP: function(e) {
                let maxResendOTPCount = localStorage.getItem('resend_otp_attemp_count');

                this.counter++;

                if (this.counter == 4) {
                    $('#resendLink').hide();
                    $('#otp-counter').text('You have exceeded maximum number of OTP request, please try again after sometime.');
                    this.setCookie('otp_enable_after', 'international_payment');
                } else {
                    this.loginPatient();
                    $('#resendLink').hide();
                }

                // if (maxResendOTPCount == 4) {
                //     $('#resendLink').hide();
                //     this.setCookie('otp_enable_on', 'international');
                //     $('#otp-counter').text('You have exceeded maximum number of OTP request, please try again after sometime.');
                // } else {
                //     this.loginPatient();
                //     $('#resendLink').hide();
                //     let xyz = parseInt((maxResendOTPCount == null) ? 1 : maxResendOTPCount);
                //     xyz = xyz+1;

                //     localStorage.setItem('resend_otp_attemp_count', xyz);
                // }

                // axios.get(`https://maxapp.in/international-patient-search?searchtype=${this.login.login_type}&term=${this.phoneWithCC}`).then(response => {
                //     this.mobileBox = false;
                //     this.otpBox = true;
                // });
            },

            setCookie: function(cname, cvalue) {
                const d = new Date();
                d.setTime(d.getTime() + (30*1000)); // for 3 min
                let expires = "expires="+ d.toUTCString();
                document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
            },

            getCookie: function(cname) {
                let name = cname + "=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for(let i = 0; i <ca.length; i++) {
                  let c = ca[i];
                  console.log(c);
                  while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                    console.log(c);
                  }
                  if (c.indexOf(name) == 0) {
                    console.log(name);
                    return c.substring(name.length, c.length);
                  }
                }
                return "";
            },

            checkCookie: function() {
                let otp_enable = this.getCookie("otp_enable_after");
                if (otp_enable != "") {
                    console.log('set true');
                    this.should_login_sometime = true;
                }else {
                    console.log('set false');
                    this.should_login_sometime = false;
                }
            },

            verifyOTP: function (e) {
                e.preventDefault();
                this.isSubmitting = true;
                this.errors.otpError = '';
                const otp = this.otpNumber;
                axios.post('https://maxapp.in/internatioanl-patient-search/verifyOTP', {
                    otp: otp,
                    to: this.phoneWithCC,
                    toForPatient: this.login.term
                }).then(response => {
                    this.isSubmitting = false;
                    if (response.data.data.Status == "Success") {
                        if (response.data.data.Patients.length) {
                            // existing user
                            this.mobileBox = false;
                            this.otpBox = false;
                            this.existingPatientBox = true;
                            this.patients = response.data.data.Patients;
                            this.visibleStep = 3;
                        } else {
                            this.patient_type = "NEW";
                            this.visibleStep = 2;
                            this.mobileBox = false;
                            this.otpBox = false;
                        }
                    } else {
                        // otp invalid
                        this.errors.otpError = "Please enter correct OTP";
                        // this.errors.otpError = response.data.message;
                    }
                }).catch(response => {
                    this.isSubmitting = false;
                    $('#resendLink').hide();
                    this.errors.otpError = 'Please try after sometime';
                })
            },

            regPatient: function(e) {
                // form validation and then submit
                let vi = this;
                vi.formTouch();
                if (vi.shouldSubmit) {
                    window.scrollTo(0,0);
                    vi.submitting = !vi.submitting;
                    this.visibleStep = 4;
                }
            },

            changeMobileNumber: function (e) {
                this.mobileBox = true;
                this.otpBox = false;
                window.location.reload()
            },

            addNewPatient: function (e) {
                window.scrollTo(0,0);
                this.visibleStep = 2;
                this.patient_type = "NEW";
                this.existing_pat_max_id = '';
            },

            payExistingPatient: function (patient, patient_max_id) {
                console.log(patient);
                this.selectedPatient = patient;
                this.GeoLocationId = patient.NationalityID;
                this.visibleStep = 4;
                this.existing_pat_max_id = patient_max_id;
                this.patient_type = 'EXISTING';
            },

            storePatientInfo: function(e) {
                let patientRegURL = '/international-online-payment/make-deposit';
                let currentElem = this;
                let patientData;

                if (currentElem.patient_type == 'NEW') {
                    patientData = currentElem.newRegPatient;
                } else {
                    patientData = currentElem.selectedPatient;
                }

                currentElem.formTouchConfirmation();

                if (currentElem.shouldSubmitConfirmation) {
                    this.isSubmitting = true;
                    currentElem.submittingConfirmation = !currentElem.submittingConfirmation;
                    axios.post(patientRegURL, {
                        data: patientData,
                        patient_type: currentElem.patient_type,
                        existing_pat_max_id: currentElem.existing_pat_max_id,
                        deposit_mode: currentElem.deposit_mode,
                        deposit_type: currentElem.deposit_type,
                        amount: currentElem.amount,
                        HospitalId: currentElem.HospitalId,
                        HospitalName: currentElem.HospitalName,
                        GeoLocationId: currentElem.GeoLocationId
                    }).then(response => {
                        let resData = response.data.data;
                        $('#isurl').val(resData.surl);
                        $('#ifurl').val(resData.furl);
                        $('#icurl').val(resData.curl);
                        $('#ikey').val(resData.key);
                        $('#ihash').val(resData.hash);
                        $('#itxnid').val(resData.TxnID);
                        $('#ifirstname').val(resData.FirstName);
                        $('#iemail').val(resData.Email);
                        $('#iphone').val(resData.MobileNumber);
                        $('#iamount').val(resData.Amount);
                        $('#iaddress1').val(resData.Address);
                        $('#icity').val(resData.City);
                        $('#icountry').val(resData.Country);
                        $("#payuForm").attr('action', resData.action);
                        this.isSubmitting = true;
                        $('.auto-pay-button').text('Redirecting to Payment Gateway...')

                        setTimeout(function() {
                            $('#payuForm').submit();
                        }, 1000);

                    });
                }
            },

            backToReg: function(e) {
                this.visibleStep = 2;
            },

            backToExistingPatientBox: function (e) {
                this.visibleStep = 3;
                this.selectedPatient = [];
                $('.error').text('');
            }
        },

        computed: {
            otpNumber() {
                return this.char1 + '' + this.char2 + '' + this.char3 + '' + this.char4;
            },

            phoneWithCC() {
                return this.login.dialCode + '' + this.login.term;
            },

            shouldSubmit() {
                let vi = this;
                return !vi.submitting && !vi.$v.newRegPatient.$invalid ? true : false;
            },

            shouldSubmitConfirmation() {
                let vi = this;

                return !vi.submittingConfirmation && !vi.$v.deposit_type.$invalid && !vi.$v.HospitalId.$invalid && !vi.$v.amount.$invalid && !vi.$v.deposit_mode.$invalid ? true : false;
            }
        },

        filters: {
            ignoreSpecificEmail: function (value) {
                if (value !== '' && value === 'info@maxhealthcare.com' || value === 'INFO@MAXHEALTHCARE.COM') {
                    return '';
                }

                return value;
            },

            formatDOB: function(value) {
                var dt = value;
                var df = "DD/MM/YYYY";
                var d2 = moment(dt, df);

                return d2.format('YYYY-MM-DD');
            }
        }
    })
}


if (document.getElementById("php-plans")) {
    const alphaSpace = helpers.regex("alphaSpace", /^[a-zA-Z\s]*$/);
    new Vue({
        name: "PhpPlansRoot",
        el: "#php-plans",
        components: {
            HospitalComponent,
            AgeComponent,
            GenderComponent,
            DiseaseComponent
        },
        data: {
            diseases: [],
            genders: [],
            ageGroup: [],
            disease: "",
            gender: "",
            age: "",
            hospital: "",
            plan: null,
            form: {
                name: "",
                email: "",
                phone: "",
                fullURL: window.location.href
            },
            phoneWithCC: "",
            dialCode: "91",
            intlPD: null,
            showContactForm: false,
            submitting: false,
            submitted: false,
            currentCountryCode: localStorage.getItem("currentCountryCode") ?? "IN",
        },

        validations: {
            form: {
                name: {
                    required: required,
                    alpha: alphaSpace
                },
                email: {
                    required: required,
                    email: email
                },
                phone: {
                    required: required,
                    digit: digitsOnly,
                    number: integer,
                    minLength: minLength(10),
                    maxLength: maxLength(14)
                },
                fullURL: {},

            }
        },
        mounted() {
            let vi = this;

            let teleDial = vi.$refs.teleDial;

            vi.intlPD = intlTelInput(teleDial, {
                initialCountry: vi.currentCountryCode,
                separateDialCode: true
            });

            teleDial.setAttribute(
                "dial",
                `+${vi.intlPD.getSelectedCountryData().dialCode}`
            );
            vi.dialCode = vi.intlPD.getSelectedCountryData().dialCode;
            vi.phoneWithCC =
                "+" +
                vi.intlPD.getSelectedCountryData().dialCode +
                vi.form.phone;
            teleDial.addEventListener(
                "countrychange",
                function(e) {
                    teleDial.setAttribute(
                        "dial",
                        `+${vi.intlPD.getSelectedCountryData().dialCode}`
                    );

                    if (teleDial.getAttribute("dial") === "+91") {
                        if (vi.form.phone.length >= 10) {
                            vi.form.phone = vi.form.phone.substring(0, 10);
                        }
                    }
                    vi.dialCode = vi.intlPD.getSelectedCountryData().dialCode;
                    vi.phoneWithCC = `+${vi.dialCode}${vi.form.phone}`;
                }.bind(this)
            );

            $("select[data-sel]")
                .niceSelect()

            .trigger("change")

            .on("change", this.handleChange);
        },

        updated() {
            let vi = this;
            vi.dialCode = vi.intlPD.getSelectedCountryData().dialCode;
        },
        computed: {
            getRoute() {
                return "/healthcare-booking/submit";
            },

            showGender() {
                return this.ageGroup.includes(this.age) ? true : false;
            },
            showDisease() {
                return this.genders.includes(this.gender) ? true : false;
            },
            showHospital() {
                return this.diseases.includes(this.disease) ? true : false;
            },
            isPhoneGood() {
                let vi = this;

                if (
                    typeof vi.form.phone === "string" &&
                    vi.form.phone.length <= 1
                ) {
                    return true;
                }

                if (vi.dialCode == "91") {
                    return isFinite(vi.form.phone) &&
                        vi.form.phone.length === 10 ?
                        true :
                        false;
                } else {
                    return isFinite(vi.form.phone) &&
                        vi.form.phone.length >= 10 &&
                        vi.form.phone.length <= 14 ?
                        true :
                        false;
                }
            },
            shouldSubmit() {
                let vi = this;
                return !vi.submitting && !vi.$v.form.$invalid && vi.isPhoneGood ?
                    true :
                    false;
            }
        },
        methods: {
            setAgeGroup(e) {
                this.ageGroup = e;
            },
            setDiseases(e) {
                this.diseases = e;
            },
            setGenders(e) {
                this.genders = e;
            },
            handleChange(e) {
                if (e.currentTarget.hasAttribute("data-gender")) {
                    this.gender = e.currentTarget.value;
                    dataLayer.push({
                        'event': 'Mhc_health_package_step2_gender',
                        'Mhc_var_health_package_step2_gender': this.gender
                    });
                } else if (e.currentTarget.hasAttribute("data-age")) {
                    this.age = e.currentTarget.value;
                    dataLayer.push({
                        'event': 'Mhc_health_package_step1_age',
                        'Mhc_var_health_package_step1_age': this.age
                    });
                } else if (e.currentTarget.hasAttribute("data-disease")) {
                    this.disease = e.currentTarget.value;
                    dataLayer.push({
                        'event': 'Mhc_health_package_step3_suffering_from',
                        'Mhc_var_health_package_step3_suffering_from': this.disease
                    });
                }
            },
            phoneOnKeyDown(e) {
                let vi = this;
                if (/^[0-9]$/i.test(e.key)) {
                    if (vi.dialCode == "91") {
                        vi.form.phone =
                            vi.form.phone.length >= 10 ?
                            vi.form.phone.substring(
                                0,
                                vi.form.phone.length - 1
                            ) :
                            vi.form.phone;
                    } else {
                        vi.form.phone =
                            vi.form.phone.length >= 14 ?
                            vi.form.phone.substring(
                                0,
                                vi.form.phone.length - 1
                            ) :
                            vi.form.phone;
                    }
                    vi.dialCode = vi.intlPD.getSelectedCountryData().dialCode;
                    vi.phoneWithCC =
                        "+" +
                        vi.intlPD.getSelectedCountryData().dialCode +
                        vi.form.phone;
                } else e.preventDefault();
            },
            hospitalSelect(e) {
                console.log(e);
                this.hospital = e.currentTarget.value;

                dataLayer.push({
                    'event': 'Mhc_health_package_step4_select_hospital',
                    'Mhc_var_health_package_step4_select_hospital': this.hospital
                });
            },


            showBookNowForm(plan) {
                if (!this.showContactForm) {
                    this.showContactForm = !this.showContactForm;
                }
                this.plan = plan;
                this.$nextTick(() => {
                    this.$refs.name.focus();
                });
                dataLayer.push({
                    'event': 'Mhc_health_package_step5_package_book_now',
                    'Mhc_var_health_package_step5_package_book_now': plan.DisplayName
                });
            },
            formRefresh() {
                setTimeout(() => {
                    window.location.href = window.location.href;
                }, 2500);
            },
            formTouch() {
                let vi = this;
                vi.$v.form.$touch();
            },
            async submitContactForm(e) {
                let vi = this;
                vi.formTouch();

                if (vi.shouldSubmit) {
                    grecaptcha.ready(async() => {
                        vi.submitting = !vi.submitting;
                        vi.phoneWithCC =
                            "+" +
                            vi.intlPD.getSelectedCountryData().dialCode +
                            vi.form.phone;

                        // const recaptcha = await recaptchaInit(
                        //     "php_package_selection"
                        // );
                        let fd = new FormData();
                        fd.append("name", vi.form.name);
                        fd.append("email", vi.form.email);
                        fd.append("phone", vi.phoneWithCC);
                        fd.append("disease", vi.disease);
                        fd.append("gender", vi.gender);
                        fd.append("age", vi.age);
                        fd.append("hospital", vi.hospital);
                        fd.append("price", vi.plan.Price);
                        fd.append("plan", vi.plan.DisplayName);
                        fd.append("fullURL", vi.form.fullURL);

                        let urlParams = new URLSearchParams(vi.form.fullURL);
                        let gclid_data = urlParams.get('gclid');

                        console.log("data pushed before", gclid_data);

                        clevertap.event.push("PHP", {
                            "Name": vi.form.name,
                            "Email": vi.form.email,
                            "Phone": vi.phoneWithCC,
                            "MyAge": vi.age,
                            "Gender": vi.gender,
                            "Disease": vi.disease,
                            "Hospital": vi.hospital,
                            "Package": vi.plan.DisplayName,
                            "Amount": vi.plan.Price,
                            "Gclid": gclid_data,
                            "fullURL": vi.form.fullURL
                        });

                        console.log("data pushed after", gclid_data);

                        // fd.append("recaptcha", recaptcha);

                        axios
                            .post(vi.getRoute, fd)
                            .then(function(response) {
                                if (response.data.status == "success") {

                                    console.log("response", response);
                                    dataLayer.push({
                                        'event': 'Mhc_health_package_step6_package_book_now_submit',
                                        'Mhc_var_health_package_step6_package_book_now_submit': 'success'
                                    });

                                    vi.submitted = !vi.submitted;
                                    vi.submitting = !vi.submitting;
                                   vi.formRefresh();
                                } else if (
                                    typeof response.data.errors == "object"
                                ) {
                                    vi.submitting = !vi.submitting;
                                    vi.formRefresh();
                                }

                            })
                            .catch(function(error) {
                                console.log("error", error);

                                dataLayer.push({
                                    'event': 'Mhc_health_package_step6_package_book_now_submit',
                                    'Mhc_var_health_package_step6_package_book_now_submit': error
                                });


                                vi.formRefresh();
                            });
                    });
                }
            }
        }
    });
}




if (document.getElementById("applicant-register")) {
    const dobFormat = helpers.regex(
        "dobFormat",
        /^(0[1-9]|1[012])[-\/.](0[1-9]|[12][0-9]|3[01])[-\/.](19|20)\d\d$/
    );
    const alphaSpace = helpers.regex("alphaSpace", /^[a-zA-Z\s]*$/);

    new Vue({
        name: "ApplicantRegistrationRoot",

        el: "#applicant-register",

        data: {
            routeData: document
                .getElementById("applicantRegistrationForm")
                .getAttribute("action"),
            form: {
                firstname: "",
                lastname: "",
                phone: "",
                email: "",
                gender: "",
                address: "",
                dob: "",
                zip: "",
                cityId: "",
                locationId: "",
                userId: "USERID",
                source: "APIKEY"
            },
            submitted: false,
            submitting: false
        },

        validations: {
            form: {
                firstname: {
                    required: required,
                    alpha: alphaSpace,
                    maxLength: maxLength(50)
                },
                lastname: {
                    required: required,
                    alpha: alphaSpace,
                    maxLength: maxLength(30)
                },

                phone: {
                    required: required,
                    number: integer,
                    minLength: minLength(10),
                    maxLength: maxLength(20)
                },

                email: {
                    required: required,
                    email: email,
                    maxLength: maxLength(50)
                },
                address: {
                    required: required,
                    maxLength: maxLength(200)
                },
                gender: {
                    required: required,
                    gender: gender =>
                        gender
                        .toString()
                        .match(new RegExp(/(MALE|FEMALE|OTHERS)/, "gi")) ?
                        true : false
                },

                dob: {
                    required: required,
                    dob: dobFormat
                },
                zip: {
                    required: required,
                    maxLength: maxLength(10)
                },
                userId: {
                    required: required
                },
                source: {
                    required: required
                },
                cityId: {
                    required: required
                },
                locationId: {
                    required: required
                }
            }
        },

        mounted() {
            try {
                let vi = this;

                vi.$nextTick(() => {
                    $('input[data-provide="datepicker"]')
                        .datepicker()
                        .on("changeDate", () => {
                            this.$v.form.dob.$model = $(
                                'input[data-provide="datepicker"]'
                            ).val();
                        });
                });
            } catch (error) {}
        },

        updated() {
            let vi = this;
        },
        computed: {
            isValidRoute() {
                let vi = this;
                let a = document.createElement("a");
                a.href = vi.routeData;
                return a.host && a.host == window.location.host;
            },
            getRoute() {
                let vi = this;
                return vi.isValidRoute ?
                    new URL(vi.routeData).pathname :
                    new URL(
                        `${window.location.href}/applicant-registration/submit`
                    ).pathname;
            },
            shouldSubmit() {
                let vi = this;
                return !vi.submitting && !vi.$v.form.$invalid ? true : false;
            }
        },

        methods: {
            formTouch() {
                let vi = this;
                vi.$v.form.$touch();
            },
            submitRegistrationApplication(e) {
                let vi = this;
                vi.formTouch();
                if (vi.shouldSubmit) {
                    vi.submitting = !vi.submitting;

                    let fd = new FormData();

                    Object.entries(vi.form).forEach((item, k) => {
                        fd.append(item[0], item[1]);
                    });

                    axios
                        .post(vi.getRoute, fd)
                        .then(function(response) {
                            if (response.data.status == "success") {
                                vi.applied = !vi.applied;
                                vi.submitting = !vi.submitting;
                                setTimeout(() => {
                                    window.location.reload();
                                }, 2000);
                            } else if (
                                typeof response.data.errors == "object"
                            ) {
                                vi.submitting = !vi.submitting;
                            }
                        })
                        .catch(function(error) {
                            console.log("error", error);
                        });
                }
            }
        }
    });
}
