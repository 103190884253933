<template>
  <div>
    <div>
      <div class="d-lg-flex align-items-center justify-content-center mb-4">
        <p class="color-base mb-0 mr-3 white-space-nowrap f-lg-20 f-18">
          Select Hospital
        </p>
        <select
          class="wide float-none col-lg-4"
          v-model="hospital"
          @change="handleSelect"
          data-sel-hospital
        >
          <option selected disabled value="">Select A Hospital</option>
          <option v-for="hospital in hospitals" :value="hospital.DisplayName">
            {{ hospital.DisplayName }}
          </option>
        </select>
      </div>
    </div>

    <div ref="premiumTable" id="premium-table" v-show="showTable">
      <ul>
        <li
          class="bg-blue"
          v-for="(plan, k) in plans"
          :key="plan.id"
          v-if="plan"
          :class="[
            k === activePlan - 1 ? 'active' : '',
            k === recommendedPlan - 1 ? 'recommend-plan' : '',
          ]"
        >
          <button @click.prevent="toggleActiveMobile($event, plan)">
            {{ plan.DisplayName }}
          </button>
        </li>
      </ul>
      <table class="mb-4">
        <thead>
          <tr>
            <th class="hide"></th>
            <th>No. of Tests &#38; Consults</th>
            <th
              class="bg-purple"
              v-for="(plan, k) in plans"
              :key="plan.id"
              v-if="plan"
              :class="[
                k === activePlan - 1 ? 'active' : '',
                k === recommendedPlan - 1 ? 'recommend-plan' : '',
              ]"
            >
              {{ plan.DisplayName }}
            </th>
          </tr>
        </thead>
        <tbody class="skel" :class="[loading ? 'd-block' : 'd-none']">
          <div class="card br">
            <div class="wrapper">
              <div class="comment br animate"></div>
            </div>
          </div>
        </tbody>
        <tbody :class="[loading ? 'd-none' : '']">
          <tr>
            <td>Amount</td>
            <td></td>
            <td
              v-for="(plan, k) in plans"
              :key="'plan_price_' + plan.id"
              v-if="plan"
              :class="[
                [
                  k === activePlan - 1 ? 'active d-table-cell' : '',
                  k === recommendedPlan - 1 ? 'recommended-plan' : '',
                ],
              ]"
            >
              <span class="txt-l">₹{{ plan.Price }}</span>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td
              v-for="(plan, k) in plans"
              :key="'plan_book_' + plan.id"
              v-if="plan"
              :class="[[k === activePlan - 1 ? 'active d-table-cell' : '']]"
            >
              <button
                type="button"
                class="btn btn-primary data-form-btn"
                :class="[plan.id === activeBookNow ? 'active shadow-lg' : '']"
                @click="showContactForm(plan)"
              >
                Book Now
              </button>
            </td>
          </tr>
        </tbody>
        <tbody
          v-html="showFeatures"
          :class="[loading ? 'd-none show-features' : 'show-features']"
        ></tbody>
        <tbody :class="[loading ? 'd-none' : '']">
          <tr>
            <td></td>
            <td></td>
            <td
              v-for="(plan, k) in plans"
              :key="plan.id"
              v-if="plan"
              :class="[[k === activePlan - 1 ? 'active d-table-cell' : '']]"
            >
              <button
                type="button"
                class="btn btn-primary data-form-btn"
                :class="[plan.id === activeBookNow ? 'active shadow-lg' : '']"
                @click="showContactForm(plan)"
              >
                Book Now
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableFeaturesData: Array,
    hospitalsData: Array,
    gendersData: Array,
    gender: String,
    age: String,
  },

  data() {
    const mediaQuery = matchMedia("(min-width: 640px)");
    return {
      hospital: "",

      plans: {},

      activePlan: 1,

      recommendedPlan: 3,

      activeBookNow: -1,

      showFeatures: "",

      matchMediaQuery: mediaQuery,

      matchMedia: mediaQuery.matches,

      loading: false,
    };
  },

  mounted() {
    this.matchMediaQuery.addEventListener("change", this.setColSpan());

    this.setColSpan();

    $("select[data-sel-hospital]")
      .niceSelect()

      .trigger("change")

      .on("change", this.handleSelect);

    $("select[data-sel-hospital]").niceSelect("update");
  },

  watch: {
    gender: function (ng, og) {
      let vi = this;
      vi.toggleLoading();

      vi.processFeatureChanges();

      vi.processChanges();

      setTimeout(
        function () {
          vi.setColSpan();

          vi.setNodes();
        }.bind(this),

        500
      );
    },
    hospital: function (ng, og) {
      let vi = this;
      vi.toggleLoading();

      vi.processFeatureChanges();

      vi.processChanges();

      setTimeout(
        function () {
          vi.setColSpan();

          vi.setNodes();
        }.bind(this),

        500
      );
    },
    age: function (na, oa) {
      let vi = this;

      vi.toggleLoading();

      vi.processFeatureChanges();

      vi.processChanges();

      setTimeout(
        function () {
          vi.setColSpan();

          vi.setNodes();
        }.bind(this),

        500
      );
    },
  },

  computed: {
    hospitals() {
      return this.hospitalsData;
    },

    showTable() {
      return this.hospital.length > 0 ? true : false;
    },
  },
  methods: {
    handleSelect(e) {
      let vi = this;

      vi.hospital = e.currentTarget.value;

      vi.toggleLoading();

      vi.processChanges();

      vi.processFeatureChanges();

      vi.setColSpan();

      setTimeout(
        function () {
          vi.toggleLoading();
        }.bind(this),

        500
      );

      vi.$emit("change", e);
    },
    showContactForm(plan) {
      let vi = this;

      vi.activeBookNow = plan.id;

      vi.$emit("book", plan);
    },
    toggleLoading() {
      let vi = this;

      vi.loading = !vi.loading;
    },
    setColSpan() {
      let vi = this;

      let seps = this.$refs.premiumTable.querySelectorAll(".sep");

      if (seps.length) {
        if (this.matchMediaQuery.matches) {
          Object.keys(seps).forEach((k) => {
            if (vi.age.length && vi.age.match(new RegExp("(5|10)", "gi"))) {
              seps[k].setAttribute("colspan", 3);
            } else {
              seps[k].setAttribute("colspan", 7);
            }
          });
        } else {
          Object.keys(seps).forEach((k) => {
            seps[k].setAttribute("colspan", 2);
          });
        }
      }
    },

    setNodes() {
      let vi = this;

      let pos = vi.activePlan;

      let nodesToHide = vi.$refs.premiumTable.querySelectorAll(
        ".show-features td:not(:nth-child(1))"
      );

      let nodesToShow = vi.$refs.premiumTable.querySelectorAll(
        `.show-features td.plan-${pos}`
      );

      Object.keys(nodesToHide).forEach((n) => {
        nodesToHide[n].style.display = "none";
      });

      Object.keys(nodesToShow).forEach((n) => {
          nodesToShow[n].style.display = "table-cell";
      });

      setTimeout(
        function () {
          vi.toggleLoading();
        }.bind(this),

        500
      );
    },

    toggleActiveMobile(e, plan) {
      let vi = this;

      vi.toggleLoading();

      if (typeof plan === "object") {
        let index = vi.plans.findIndex((pl, k, a) => {
          return pl.Name === plan.Name;
        });

        vi.activePlan = index < 0 ? 1 : index + 1;
      } else {
        vi.activePlan = 1;
      }

      vi.setNodes();
    },

    processChanges() {
      
      let vi = this;    
      

      let hospital = vi.hospitalsData.filter((gen) => {
        return gen.DisplayName.match(new RegExp(`${vi.hospital}`, "gi"));
      });
      

      if (hospital.length) {
        if (vi.age.length && vi.age.match(new RegExp("(2|3)", "gi"))) {
          vi.plans = hospital[0].plans.filter((pl) => {
            return pl.CHILD;
          });
        } else if (
          vi.gender.length &&
          vi.gender.match(new RegExp("^male$", "gi"))
        ) {
          vi.plans = hospital[0].plans.filter((pl) => {
            return pl.M_F && !pl.CHILD;
          });
        } else if (
          vi.gender.length &&
          vi.gender.match(new RegExp("^female$", "gi"))
        ) {
          vi.plans = hospital[0].plans.filter((pl) => {
            return !pl.M_F && !pl.CHILD;
          });
        }
      } else {
        vi.plans = [];
      }
    },

    processFeatureChanges() {


      let vi = this;

      let hospital = vi.hospitalsData.filter((gen) => {
        return gen.DisplayName.match(new RegExp(`${vi.hospital}`, "gi"));
      });

          
      let genderOne = vi.gendersData.filter((genSet) => {
        return genSet.Name.match(new RegExp(`^${vi.gender}`, "gi"));
      });
      
      let hospitalID = hospital[0].id;
      let genderID = genderOne[0].id;    
     
      if(typeof  hospitalID !='undefined' && typeof  genderID !='undefined'){
            let tableFeature = vi.tableFeaturesData.filter((e) => {
            return (e.PhpGender == genderID && e.PhpHospital == hospitalID);
            });

            if(tableFeature.length){
                    vi.showFeatures = tableFeature[0].TableStructure;
              }else{
              //vi.showFeatures =  vi.tableFeaturesData[0].TableStructure;
               if(screen.width > 640){
                   vi.showFeatures ='<tr> <td colspan="7" class="sep">No packages details has been added in backend</td></tr>';
               }else{
                   vi.showFeatures ='<tr> <td colspan="2" class="sep">No packages details has been added in backend</td></tr>';
               }
              }
      }

      

      
     /*


      if (vi.age.length && vi.age.match(new RegExp("(2|3)", "gi"))) {
        let feature = vi.gendersData.filter((gen) => {
          return gen.DisplayName.match(new RegExp("^child", "gi"));
        });

        if (typeof feature[0] !== "undefined") {
          vi.showFeatures = feature[0].Features;
        }
      } else {
        let feature = vi.gendersData.filter((gen) => {
          return gen.DisplayName.match(new RegExp(`${vi.gender}`, "gi"));
        });

        vi.showFeatures =
          typeof feature[0] !== "undefined" ? feature[0].Features : "";
      }
      */
    




    },
  },
};
</script>

<style scoped>
tbody.skel {
  width: 66vw;
  height: 80vh;
}

@media screen and (max-width: 1366px) {
  tbody.skel {
    width: 92vw;
    height: 80vh;
  }
}
@media screen and (max-width: 1199px) {
  tbody.skel {
    width: 95vw;
    height: 80vh;
  }
}
@media screen and (max-width: 576px) {
  tbody.skel {
    width: 90vw;
    height: 80vh;
  }
}

.card {
  display: block;
  border: 2px solid #fff;
  box-shadow: 0px 0px 10px 0 #a9a9a980;
  padding: 20px 20px;
  width: 100%;
  height: 100%;
  border-radius: 0.4rem;
}

.wrapper {
  height: 100%;
  width: 0px;
  animation: fullView 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.comment {
  height: 100%;
  background: #777;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

.animate {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
</style>
