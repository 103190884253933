<template>
  <div>
    <div class="d-lg-flex align-items-center justify-content-center mb-4">
            <p class="color-base mb-0 mr-3 white-space-nowrap f-lg-20 f-18">My age is</p>
            <select data-sel class="wide float-none gender-dropdown" data-age>
                <option disabled selected value="">Select Age</option>
                <option v-for="age in ageGroupData">{{ age.AgeGroupText }}</option>
            </select>

        </div>
  </div>
</template>

<script>
export default {
  props: {
    agegroupData: Array,
  },

  data() {    
    return {
      ageGroup: [],
    };
  },
   mounted() {
      this.ageGroupArray()
   },
  methods:{
        ageGroupArray(){
          this.agegroupData.map( (data) => {        
          this.ageGroup.push(data.AgeGroupText);
        });
          this.$emit("age-group-dataset", this.ageGroup);

        }
     },

 
  computed: {
    ageGroupData() {      
      return this.agegroupData;
    },  
  }
  
};
</script>

