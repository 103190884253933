<template>
  <div>
    <h2
      v-if="!applied"
      class="
        site-heading-with-border
        f-lg-28 f-26
        mb-30
        color-blue
        font-weight-semi-bold
      "
    >
      <span>Submit your application</span>
    </h2>
    <form v-if="!applied">
      <div class="row">
        <div class="col-lg-4 form-group">
          <label class="gray-600 font-weight-normal mb-1" for="fname">
            Name
          </label>
          <input
            type="text"
            :class="[
              $v.form.name.$error ? 'is-invalid' : '',
              !$v.form.name.$invalid ? 'is-valid' : '',
            ]"
            @input="$v.form.name.$touch()"
            @blur="$v.form.name.$touch()"
            v-model="$v.form.name.$model"
            class="form-control"
            :disabled="submitting"
            placeholder="Enter Your Name"
            required
          />
          <div v-if="!$v.form.name.required" class="invalid-feedback">
            Name is Required
          </div>
          <div v-else-if="!$v.form.name.alphaSpace" class="invalid-feedback">
            Only Enter Valid Characters
          </div>
        </div>

        <div class="col-lg-4 form-group">
          <label class="gray-600 font-weight-normal mb-1" for="fname">
            Contact No.
          </label>
          <input
            type="text"
            class="form-control"
            :disabled="submitting"
            :class="[
              $v.form.phone.$error ? 'is-invalid' : '',
              !$v.form.phone.$invalid ? 'is-valid' : '',
            ]"
            @input="$v.form.phone.$touch()"
            @blur="$v.form.phone.$touch()"
            v-model="$v.form.phone.$model"
            placeholder="Please Enter your contact number"
            required
          />
          <div v-if="!$v.form.phone.required" class="invalid-feedback">
            Phone is Required
          </div>
          <div v-else-if="!$v.form.phone.number" class="invalid-feedback">
            Phone should be a digit
          </div>
          <div v-else-if="!$v.form.phone.minLength" class="invalid-feedback">
            Phone should be at least
            {{ $v.form.phone.$params.minLength.min }} digit
          </div>
          <div v-else-if="!$v.form.phone.maxLength" class="invalid-feedback">
            Phone should be at max
            {{ $v.form.phone.$params.maxLength.max }} digit
          </div>
        </div>
        <div class="col-lg-4 form-group">
          <label class="gray-600 font-weight-normal mb-1" for="fname">
            Email ID
          </label>
          <input
            type="text"
            :class="[
              $v.form.email.$error ? 'is-invalid' : '',
              !$v.form.email.$invalid ? 'is-valid' : '',
            ]"
            @input="$v.form.email.$touch()"
            @blur="$v.form.email.$touch()"
            v-model="$v.form.email.$model"
            class="form-control"
            :disabled="submitting"
            placeholder="Email ID"
            required
          />
          <div v-if="!$v.form.email.required" class="invalid-feedback">
            Email is Required
          </div>
          <div v-else-if="!$v.form.email.email" class="invalid-feedback">
            Email should be a valid Email
          </div>
        </div>
        <div class="col-lg-4 form-group">
          <label class="gray-600 font-weight-normal mb-1" for="fname">
            Date of Birth
          </label>
          <input
            data-provide="datepicker"
            type="text"
            class="form-control"
            :disabled="submitting"
            :class="[
              $v.form.dob.$error ? 'is-invalid' : '',
              !$v.form.dob.$invalid ? 'is-valid' : '',
            ]"
            @input="$v.form.dob.$touch()"
            @blur="$v.form.dob.$touch()"
            v-model="$v.form.dob.$model"
            placeholder="DD/MM/YYYY"
            required
          />
          <div v-if="!$v.form.dob.required" class="invalid-feedback">
            Date Of Birth is Required
          </div>
        </div>
        <div class="col-lg-4 form-group">
          <label class="gray-600 font-weight-normal mb-1" for="fname">
            Current Location
          </label>
          <input
            type="text"
            class="form-control"
            :disabled="submitting"
            :class="[
              $v.form.currentLocation.$error ? 'is-invalid' : '',
              !$v.form.currentLocation.$invalid ? 'is-valid' : '',
            ]"
            @input="$v.form.currentLocation.$touch()"
            @blur="$v.form.currentLocation.$touch()"
            v-model="$v.form.currentLocation.$model"
            placeholder="Where are you currently located"
            required
          />
          <div
            v-if="!$v.form.currentLocation.required"
            class="invalid-feedback"
          >
            Value Required
          </div>
          <div
            v-else-if="!$v.form.currentLocation.alphaSpace"
            class="invalid-feedback"
          >
            Only Enter Valid Characters
          </div>
        </div>
        <div class="col-lg-4 form-group">
          <label class="gray-600 font-weight-normal mb-1" for="fname">
            Qualification
          </label>
          <input
            type="text"
            class="form-control"
            :disabled="submitting"
            :class="[
              $v.form.qualification.$error ? 'is-invalid' : '',
              !$v.form.qualification.$invalid ? 'is-valid' : '',
            ]"
            @input="$v.form.qualification.$touch()"
            @blur="$v.form.qualification.$touch()"
            v-model="$v.form.qualification.$model"
            placeholder="What's your qualification"
            required
          />
          <div v-if="!$v.form.qualification.required" class="invalid-feedback">
            Qualification Required
          </div>
          <div
            v-else-if="!$v.form.qualification.alphaSpace"
            class="invalid-feedback"
          >
            Only Enter Valid Characters
          </div>
        </div>
        <div class="col-lg-4 form-group">
          <span
            class="d-inline-block gray-600 font-weight-normal mb-1"
            for="fname"
          >
            Total Work Experience
          </span>
          <div class="row no-gutters">
            <div class="col-6 pr-10">
              <input
                type="text"
                class="form-control"
                :disabled="submitting"
                :class="[
                  $v.form.totalWorkExperienceMonth.$error ? 'is-invalid' : '',
                  !$v.form.totalWorkExperienceMonth.$invalid ? 'is-valid' : '',
                ]"
                @input="$v.form.totalWorkExperienceMonth.$touch()"
                @blur="$v.form.totalWorkExperienceMonth.$touch()"
                v-model="$v.form.totalWorkExperienceMonth.$model"
                placeholder="Month"
                required
              />
              <div
                v-if="!$v.form.totalWorkExperienceMonth.required"
                class="invalid-feedback"
              >
                Value Required
              </div>
              <div
                v-else-if="!$v.form.totalWorkExperienceMonth.number"
                class="invalid-feedback"
              >
                Should be a digit
              </div>
              <div
                v-else-if="!$v.form.totalWorkExperienceMonth.maxLength"
                class="invalid-feedback"
              >
                Max
                {{ $v.form.totalWorkExperienceMonth.$params.maxLength.max }}
                digit
              </div>
            </div>
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                :disabled="submitting"
                :class="[
                  $v.form.totalWorkExperienceYear.$error ? 'is-invalid' : '',
                  !$v.form.totalWorkExperienceYear.$invalid ? 'is-valid' : '',
                ]"
                @input="$v.form.totalWorkExperienceYear.$touch()"
                @blur="$v.form.totalWorkExperienceYear.$touch()"
                v-model="$v.form.totalWorkExperienceYear.$model"
                placeholder="Year"
                required
              />
              <div
                v-if="!$v.form.totalWorkExperienceYear.required"
                class="invalid-feedback"
              >
                Value Required
              </div>
              <div
                v-else-if="!$v.form.totalWorkExperienceYear.number"
                class="invalid-feedback"
              >
                Should be a digit
              </div>
              <div
                v-else-if="!$v.form.totalWorkExperienceYear.maxLength"
                class="invalid-feedback"
              >
                Max
                {{ $v.form.totalWorkExperienceYear.$params.maxLength.max }}
                digit
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 form-group">
          <span
            class="d-inline-block gray-600 font-weight-normal mb-1"
            for="fname"
          >
            Current CTC
          </span>
          <div class="row no-gutters">
            <div class="col-6 pr-10">
              <input
                type="text"
                class="form-control"
                :disabled="submitting"
                :class="[
                  $v.form.currentCTCInLac.$error ? 'is-invalid' : '',
                  !$v.form.currentCTCInLac.$invalid ? 'is-valid' : '',
                ]"
                @input="$v.form.currentCTCInLac.$touch()"
                @blur="$v.form.currentCTCInLac.$touch()"
                v-model="$v.form.currentCTCInLac.$model"
                placeholder="In Lac."
                required
              />
              <div
                v-if="!$v.form.currentCTCInLac.required"
                class="invalid-feedback"
              >
                Value Required
              </div>
              <div
                v-else-if="!$v.form.currentCTCInLac.number"
                class="invalid-feedback"
              >
                Should be a digit
              </div>
              <div
                v-else-if="!$v.form.currentCTCInLac.maxLength"
                class="invalid-feedback"
              >
                Max {{ $v.form.currentCTCInLac.$params.maxLength.max }} digit
              </div>
            </div>
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                :disabled="submitting"
                :class="[
                  $v.form.currentCTCInThousand.$error ? 'is-invalid' : '',
                  !$v.form.currentCTCInThousand.$invalid ? 'is-valid' : '',
                ]"
                @input="$v.form.currentCTCInThousand.$touch()"
                @blur="$v.form.currentCTCInThousand.$touch()"
                v-model="$v.form.currentCTCInThousand.$model"
                placeholder="In Thousands"
                required
              />
              <div
                v-if="!$v.form.currentCTCInThousand.required"
                class="invalid-feedback"
              >
                Value Required
              </div>
              <div
                v-else-if="!$v.form.currentCTCInThousand.number"
                class="invalid-feedback"
              >
                Should be a digit
              </div>
              <div
                v-else-if="!$v.form.currentCTCInThousand.maxLength"
                class="invalid-feedback"
              >
                Max
                {{ $v.form.currentCTCInThousand.$params.maxLength.max }} digit
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 form-group">
          <label class="gray-600 font-weight-normal mb-1" for="fname">
            Last Industry
          </label>
          <input
            type="text"
            class="form-control"
            :disabled="submitting"
            :class="[
              $v.form.lastIndustry.$error ? 'is-invalid' : '',
              !$v.form.lastIndustry.$invalid ? 'is-valid' : '',
            ]"
            @input="$v.form.lastIndustry.$touch()"
            @blur="$v.form.lastIndustry.$touch()"
            v-model="$v.form.lastIndustry.$model"
            placeholder="Please Select Current Industry"
            required
          />
          <div v-if="!$v.form.lastIndustry.required" class="invalid-feedback">
            Value Required
          </div>
          <div
            v-else-if="!$v.form.lastIndustry.alphaSpace"
            class="invalid-feedback"
          >
            Only Enter Valid Characters
          </div>
        </div>
        <div class="col-lg-4 form-group">
          <label class="gray-600 font-weight-normal mb-1" for="fname">
            Last Employer
          </label>
          <input
            type="text"
            class="form-control"
            :disabled="submitting"
            :class="[
              $v.form.lastEmployer.$error ? 'is-invalid' : '',
              !$v.form.lastEmployer.$invalid ? 'is-valid' : '',
            ]"
            @input="$v.form.lastEmployer.$touch()"
            @blur="$v.form.lastEmployer.$touch()"
            v-model="$v.form.lastEmployer.$model"
            placeholder="Please enter current employer"
            required
          />
          <div v-if="!$v.form.lastEmployer.required" class="invalid-feedback">
            Value Required
          </div>
          <div
            v-else-if="!$v.form.lastEmployer.alphaSpace"
            class="invalid-feedback"
          >
            Only Enter Valid Characters
          </div>
        </div>
        <div class="col-lg-4 form-group">
          <label class="gray-600 font-weight-normal mb-1" for="fname">
            Last Designation
          </label>
          <input
            type="text"
            class="form-control"
            :disabled="submitting"
            :class="[
              $v.form.lastDesignation.$error ? 'is-invalid' : '',
              !$v.form.lastDesignation.$invalid ? 'is-valid' : '',
            ]"
            @input="$v.form.lastDesignation.$touch()"
            @blur="$v.form.lastDesignation.$touch()"
            v-model="$v.form.lastDesignation.$model"
            placeholder="Enter your current designation"
            required
          />
          <div
            v-if="!$v.form.lastDesignation.required"
            class="invalid-feedback"
          >
            Value Required
          </div>
          <div
            v-else-if="!$v.form.lastDesignation.alphaSpace"
            class="invalid-feedback"
          >
            Only Enter Valid Characters
          </div>
        </div>

        <div class="col-lg-8 form-group">
          <div
            class="bg-gray-100 px-3 py-lg-20 py-10 rounded-lg"
            :class="[
              isFileDirty && !isValidFile ? 'is-file-invalid' : '',
              isFileDirty && isValidFile ? 'is-file-valid' : '',
            ]"
          >
            <div class="row no-gutters justify-content-between">
              <div class="col-lg-8">
                <p
                  class="color-gray-600 font-weight-semi-bold m-0"
                  v-if="uploadFile"
                >
                  You Have Uploaded : {{ uploadFileName }}
                </p>
                <p
                  class="text-danger font-weight-semi-bold m-0"
                  v-else-if="!isValidFile && isFileDirty"
                >
                  You need to upload a Resume
                </p>
                <p class="color-gray-600 font-weight-semi-bold m-0" v-else>
                  Upload Resume
                </p>
                <p class="color-gray-600">
                  Files must be <strong>less than 2 MB</strong>
                  <span class="d-inline-block mx-2">|</span>
                  Allowed file types: <strong>pdf, doc, docx</strong>
                </p>
              </div>
              <div class="col-lg-4">
                <label
                  class="
                    btn-with-icon
                    f-lg-20 f-17
                    btn-with-icon-sm
                    pr-5
                    pl-3
                    ml-lg-auto
                  "
                >
                  <input
                    type="file"
                    @change="onFileChange"
                    accept=".pdf,.docx,.doc"
                    :disabled="submitting"
                    hidden
                    required
                  />
                  Choose File
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 form-group">
          <img
            class="img-fluid"
            src="/assets/img/not-a-robot-placeholder.png"
            alt=""
          />
        </div>
      </div>
      <button
        type="button"
        @click.prevent="submitJobApplication"
        :disabled="submitting && !shouldSubmit"
        class="
          btn-with-icon btn-with-icon-sm
          f-lg-20 f-17
          pr-5
          pl-2
          border-0
          w-100
        "
      >
        Submit
      </button>
    </form>
    <h2
      v-if="applied"
      class="
        site-heading-with-border
        f-lg-28 f-26
        mb-30
        color-blue
        font-weight-semi-bold
      "
    >
      <span>Thanks for your application</span>
    </h2>
    <div class="card shadow" v-if="applied">
      <div class="card-header bg-info text-light font-weight-bold">
        Your Application has been submitted
      </div>
      <div class="card-body">We'll get back to you :);</div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
  integer,
  alpha,
  alphaNum,
  helpers,
} from "vuelidate/lib/validators";
import { recaptchaInit } from "../../js/recaptcha.js";
const alphaSpace = helpers.regex("alphaSpace", /^[a-zA-Z\s0-9&-_]*$/);

export default {
  props: { routeData: String, jobData: String },
  data() {
    return {
      form: {
        name: "",

        phone: "",

        email: "",

        dob: "",

        currentLocation: "",

        totalWorkExperienceMonth: "",

        totalWorkExperienceYear: "",

        currentCTCInLac: "",

        currentCTCInThousand: "",

        lastIndustry: "",

        lastEmployer: "",

        lastDesignation: "",

        qualification: "",
      },
      attachments: {
        value: {},
        dirty: false,
      },
      submitting: false,
      applied: false,
    };
  },

  validations: {
    form: {
      name: {
        required: required,
        alpha: alphaSpace,
      },

      phone: {
        required: required,
        number: integer,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },

      email: {
        required: required,
        email: email,
      },

      dob: {
        required: required,
      },

      currentLocation: {
        required: required,
        alpha: alphaSpace,
      },

      totalWorkExperienceMonth: {
        required: required,
        number: integer,
        maxLength: maxLength(2),
      },

      totalWorkExperienceYear: {
        required: required,
        number: integer,
        maxLength: maxLength(2),
      },

      currentCTCInLac: {
        required: required,
        number: integer,
        maxLength: maxLength(2),
      },

      currentCTCInThousand: {
        required: required,
        number: integer,
        maxLength: maxLength(2),
      },

      lastIndustry: {
        required: required,
        alpha: alphaSpace,
      },

      lastEmployer: {
        required: required,
        alpha: alphaSpace,
      },

      lastDesignation: {
        required: required,
        alpha: alphaSpace,
      },
      qualification: {
        required: required,
        alpha: alphaSpace,
      },
    },
  },

  mounted() {
    try {
      let vi = this;

      vi.$nextTick(() => {
        $('input[data-provide="datepicker"]')
          .datepicker()
          .on("changeDate", () => {
            this.$v.form.dob.$model = $(
              'input[data-provide="datepicker"]'
            ).val();
          });
      });
    } catch (error) {}
  },
  computed: {
    isValidRoute() {
      let vi = this;
      let a = document.createElement("a");
      a.href = vi.routeData;
      return a.host && a.host == window.location.host;
    },
    getRoute() {
      let vi = this;
      return vi.isValidRoute
        ? new URL(vi.routeData).pathname
        : new URL(`${window.location.href}/application/submit`).pathname;
    },
    uploadFile() {
      let vi = this;
      return typeof vi.attachments.value[0] !== "undefined" ? true : false;
    },
    uploadFileName() {
      let vi = this;
      return typeof vi.attachments.value[0] !== "undefined"
        ? vi.attachments.value[0].name
        : "";
    },
    isFileDirty() {
      let vi = this;
      return vi.attachments.dirty;
    },
    isValidFile() {
      let vi = this;
      return Boolean(
        Object.keys(vi.attachments.value).length && vi.attachments.dirty
      )
        ? true
        : false;
    },
    shouldSubmit() {
      let vi = this;
      return !vi.submitting && !vi.$v.form.$invalid && vi.isValidFile
        ? true
        : false;
    },
  },

  methods: {
    onFileChange(e) {
      let vi = this;
      vi.attachments.dirty = true;
      e.stopPropagation();
      let files = e.target.files || e.dataTransfer.files;
      if (files.length) {
        let f = e.target.files[0];
        if (f.size >= 2 * 1024 * 1024) {
          Toast.fire({
            icon: "warning",
            title: "Only upload file of maximum 2MB!",
          });
          e.target.value = null;
          this.attachments.value = {};
          e.preventDefault();
          return false;
        }
        if (
          !f.type.match(
            "(image/jp.*|application/pdf|application/doc.*|image/pn.*|application/vnd.openxmlformats-officedocument.wordprocessingml.document)"
          )
        ) {
          if (window.hasOwnProperty("Toast")) {
            Toast.fire({
              icon: "warning",
              title: "We Support PDF, DOC, JPG & PNG!",
            });
          }
          e.target.value = null;
          this.attachments.value = {};
          e.preventDefault();
          return false;
        }

        let fl = new FileReader();
        fl.onload = (e) => {
          let bs = new Uint8Array(e.target.result);
          let i =
            bs.length > 4 &&
            bs[0] == 0xff &&
            bs[1] == 0xd8 &&
            bs[2] == 0xff &&
            bs[3] == 0xe0;
          let x =
            bs.length > 4 &&
            bs[0] == 0x50 &&
            bs[1] == 0x4b &&
            bs[2] == 0x03 &&
            bs[3] == 0x04;
          let dx =
            bs.length > 4 &&
            bs[0] == 0x50 &&
            bs[1] == 0x4b &&
            bs[2] == 0x05 &&
            bs[3] == 0x06;
          let dcx =
            bs.length > 4 &&
            bs[0] == 0x50 &&
            bs[1] == 0x4b &&
            bs[2] == 0x07 &&
            bs[3] == 0x08;
          let p =
            bs.length > 5 &&
            bs[0] == 0x25 &&
            bs[1] == 0x50 &&
            bs[2] == 0x44 &&
            bs[3] == 0x46 &&
            bs[4] == 0x2d;
          let d =
            bs.length > 8 &&
            bs[0] == 0xd0 &&
            bs[1] == 0xcf &&
            bs[2] == 0x11 &&
            bs[3] == 0xe0 &&
            bs[4] == 0xa1 &&
            bs[5] == 0xb1 &&
            bs[6] == 0x1a &&
            bs[7] == 0xe1;
          let g =
            bs.length > 8 &&
            bs[0] == 0x89 &&
            bs[1] == 0x50 &&
            bs[2] == 0x4e &&
            bs[3] == 0x47 &&
            bs[4] == 0x0d &&
            bs[5] == 0x0a &&
            bs[6] == 0x1a &&
            bs[7] == 0x0a;
          if (i || p || d || g || x || dx || dcx) {
            this.attachments.value = files;
          } else {
            if (window.hasOwnProperty("Toast")) {
              Toast.fire({
                icon: "warning",
                title: "We Support PDF, DOC, JPG & PNG!",
              });
            }
            e.target.value = null;
            this.attachments.value = {};
            e.preventDefault();
            return false;
          }
        };
        fl.readAsArrayBuffer(f);
      }
    },
    formTouch() {
      let vi = this;
      vi.$v.form.$touch();
      vi.attachments.dirty = true;
    },
    async submitJobApplication(e) {
      let vi = this;
      vi.formTouch();
      if (vi.shouldSubmit) {
        grecaptcha.ready(async () => {
          vi.submitting = !vi.submitting;

          const recaptcha = await recaptchaInit("job_application");

          let fd = new FormData();

          Object.entries(vi.form).forEach((item, k) => {
            fd.append(item[0], item[1]);
          });

          if (vi.attachments.value.hasOwnProperty("0")) {
            fd.append("resume", vi.attachments.value[0]);
          }

          fd.append("jobData", vi.jobData);

          fd.append("recaptcha", recaptcha);

          axios
            .post(vi.getRoute, fd)
            .then(function (response) {
              if (response.data.status == "success") {
                vi.applied = !vi.applied;
                vi.submitting = !vi.submitting;
                window.scrollTo({
                  top: 800,
                  behavior: "smooth",
                });
                setTimeout(() => {
                  window.location.reload();
                }, 3000);
              } else if (typeof response.data.errors == "object") {
                vi.submitting = !vi.submitting;
              }
            })
            .catch(function (error) {
              console.log("error", error);
            });
        });
      }
    },
  },
};
</script>

<style>
</style>
