<template>
   <div class="bg-green-darken rounded-lg px-lg-20 px-3 pt-20 pb-lg-35 pb-30">
      <div class="col-11 px-0">
         <h3 class="color-white font-weight-bold f-24 mb-lg-10 mb-2">
            Contact Us
         </h3>
         <p class="color-white f-lg-17 mb-lg-3 mb-35">
            Please fill the form below for registration
         </p>
         <form>
            <div class="form-group mb-20">
               <input
                  type="text"
                  name="fullname"
                  class="form-control f-16 py-10 mt-3 mb-1"
                  :class="[
                  $v.form.fullname.$error ? 'is-invalid' : '',
                  !$v.form.fullname.$invalid ? 'is-valid' : '',
                  ]"
                  @input="$v.form.fullname.$touch()"
                  @blur="$v.form.fullname.$touch()"
                  v-model="$v.form.fullname.$model"
                  :disabled="submitting"
                  placeholder="Enter Full Name*"
                  required
                  />
              
               <input
                  type="text"
                  name="email"
                  class="form-control f-16 py-10 mt-3 mb-3"
                  :class="[
                  $v.form.email.$error ? 'is-invalid' : '',
                  !$v.form.email.$invalid ? 'is-valid' : '',
                  ]"
                  @input="$v.form.email.$touch()"
                  @blur="$v.form.email.$touch()"
                  v-model="$v.form.email.$model"
                  :disabled="submitting"
                  placeholder="Enter Email Id*"
                  required
                  />
               <div v-if="!$v.form.email.required && $v.form.email.$dirty" class="invalid-feedback">
                  Email is Required
               </div>
               <div v-else-if="!$v.form.email.email" class="invalid-feedback">
                  Email should be a valid Email
               </div>
               <input
                  type="text"
                  name="phone"
                  class="form-control f-16 py-10 mt-3 mb-1"
                  :class="[
                  $v.form.phone.$error ? 'is-invalid' : '',
                  !$v.form.phone.$invalid ? 'is-valid' : '',
                  ]"
                  id="phone"
                  minlength="5"
                  maxlength="14"
                  tele-dial-academic
                  @input="$v.form.phone.$touch()"
                  @blur="$v.form.phone.$touch()"
                  v-model="$v.form.phone.$model"
                  :disabled="submitting"
                  placeholder="Enter Phone Number*"
                  required
                  />  

                  <span id="phone-error-education" style="color:white;"></span>

                

               <div v-if="$v.form.phone.$error && $v.form.phone.$dirty" class="invalid-feedback">
                  Phone is Required
               </div>
               <div v-else-if="!$v.form.phone.minLength && $v.form.phone.$dirty" class="invalid-feedback">
                  Phone should be at least {{ $v.form.phone.$params.minLength.min }} digit
               </div>
               <div v-else-if="!$v.form.phone.maxLength && $v.form.phone.$dirty" class="invalid-feedback">
                  Phone should be at max {{ $v.form.phone.$params.maxLength.max }} digit
               </div>
            </div>
            <input type="hidden" id="academic-country" name="country" class="form-control" placeholder="Select Phone Number*" readonly value="" data-press-country />
            <div class="form-group mb-20">
               <div class="custom-control custom-checkbox">
                  <input
                     type="checkbox"
                     name="accept"
                     class="custom-control-input"
                     id="cb"
                     required
                     :disabled="submitting"
                     checked
                     :class="[
                     $v.form.terms.$error ? 'is-invalid' : '',
                     !$v.form.terms.$invalid ? 'is-valid' : '',
                     ]"
                     @input="$v.form.terms.$touch()"
                     @blur="$v.form.terms.$touch()"
                     v-model="$v.form.terms.$model"
                     />
                  <label
                     class="
                     f-base
                     color-white
                     font-weight-normal
                     custom-control-label custom-control-label-outline-white
                     "
                     for="cb"
                     >
                  <a
                     href="https://www.maxhealthcare.in/terms-and-conditions"
                     class="color-white hover-color-white hover-no-underline"
                     target="_blank"
                     >I agree to the terms and conditions</a
                     >
                  </label>
                  <div v-if="!$v.form.terms.required" class="invalid-feedback">
                     Please Agree to the terms
                  </div>
               </div>
            </div>
            <button
               @click.prevent="submitEducationApplication"
               :disabled="submitting && !shouldSubmit"
               type="button"
               class="
               btn-with-icon btn-with-icon-shade
               border-0
               f-lg-20
               w-100
               btn-with-icon-sm btn-with-icon-xs
               py-lg-3
               pl-0
               pr-5
               "
               >
            Submit
            </button>
            <p class="f-lg-16 f-15
               color-white mb-0 mt-3 ">Email - digitalquery@maxhealthcare.com</p>
         </form>
      </div>
      <div class="card shadow my-4" v-if="submitted">
         <div class="card-header bg-info text-light font-weight-bold">
            Your Application has been submitted
         </div>
         <div class="card-body">We'll get back to you soon.</div>
      </div>
   </div>
</template>
<script>
   import {
     required,
     minLength,
     maxLength,
     email,
     integer,
     alpha,
     alphaNum,
     helpers,
   } from "vuelidate/lib/validators";  
   const alphaSpace = helpers.regex("alphaSpace", /^[a-zA-Z\s]*$/);
   import { recaptchaInit } from "../../js/recaptcha.js";
   const boolCheck = (value) => typeof value === "boolean" && value === true;
   export default {
     props: { routeData: String, url: String, source: String },
     data() {
       return {
         form: {
           fullname: "",
   
           email: "",
   
           phone: "",
   
           terms: true,
         },
   
         submitting: false,
   
         submitted: false,
       };
     },
   
     validations: {
       form: {
         fullname: {
           required: required,
           alpha: alphaSpace,
         },
   
         phone: {
           required: required,
           number: integer,
           minLength: minLength(5),
           maxLength: maxLength(14),
         },
   
         email: {
           required: required,
           email: email,
         },
   
         terms: {
           required: boolCheck,
         },
       },
   
       checked: {
         required: required,
       },
     },
   
     mounted() {},
     computed: {
   
   
       shouldSubmit() {
         let vi = this;
         return !vi.submitting && !vi.$v.form.$invalid ? true : false;
       },
   
       getRoute() {
         let vi = this;
         return vi.isValidRoute
           ? new URL(vi.routeData).pathname
           : new URL(`${window.location.href}/application/submit`).pathname;
       },
       isValidRoute() {
         let vi = this;
         let a = document.createElement("a");
         a.href = vi.routeData;
         return a.host && a.host == window.location.host;
       },
     },
   
     watch: {
       submitted: function (ols, nes) {
         let vi = this;
         vi.moduleInitState();
       },
     },
   
     methods: {
       moduleInitState() {
         let vi = this;
   
         if (vi.submitted === true) {
           vi.$nextTick(() => {
             vi.form.fullname = "";
   
             vi.form.email = "";
   
             vi.form.phone = "";
   
             vi.$v.form.$reset();
           });
         }
       },
   
      async submitEducationApplication(e) {
    let vi = this;
    vi.formTouch();
    
    // New variable to track validation errors
    let phoneError = '';

    if (vi.shouldSubmit) {
        grecaptcha.ready(async () => {
            const selectedCountry = document.getElementById("academic-country").value;
          
            let fd = new FormData();

            Object.entries(vi.form).forEach((item, k) => {
                fd.append(item[0], item[1]);
                console.log("form data", item[0] + ' ->>' + item[1]);
            });

            fd.append("leadSource", vi.source);
            fd.append("leadURL", vi.url);
            console.log("leadSource", vi.source);
            console.log("leadURL", vi.url);

            fd.append("country", selectedCountry);




          let phoneNuber = vi.form.phone;           
          let sanitizedPhoneNumber = phoneNuber.replace(/\D/g, '');
          if (selectedCountry == "India (भारत)") {              
              if (sanitizedPhoneNumber && sanitizedPhoneNumber.length != 10) {
                  phoneError = 'Please provide a valid number!';       
              }else{
                  document.getElementById("phone-error-education").innerHTML = '';
              }
          }
          if(phoneError) {
          document.getElementById("phone-error-education").innerHTML = phoneError;
          return false;
          }

            vi.submitting = !vi.submitting;

            axios
                .post(vi.getRoute, fd)
                .then(function (response) {
                    if (response.data.status == "success") {    
                      document.getElementById("phone-error-education").innerHTML = '';                                     
                        vi.submitting = !vi.submitting;
                        vi.submitted = !vi.submitted;
                        setTimeout(() => {
                            vi.submitted = !vi.submitted;
                        }, 3000);
                    } else if (typeof response.data.errors == "object") {    
                      document.getElementById("phone-error-education").innerHTML = '';                
                        vi.submitting = !vi.submitting;
                        setTimeout(() => {                           
                            window.location.reload();
                        }, 1500);
                    }
                })
                .catch(function (error) {
                    console.log("error", error);
                });
        });
    }
},

       formTouch() {
         let vi = this;
         vi.$v.form.$touch();
       },
     },
   };
</script>
<style lang="scss" scoped>
</style>