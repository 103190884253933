<template>
  <div>
    <div>
            <div class="d-lg-flex align-items-center justify-content-center mb-4">
                <p class="color-base mb-0 mr-3 f-lg-20 f-18">Are you or someone in your family suffering from</p>
                <select data-sel class="wide float-none col-lg-2" data-disease>
                    <option disabled selected value="">Select A Disease</option>
                    <option v-for="disease in diseasesAllData">{{ disease.Name }}</option>
                </select>
            </div>
        </div>
  </div>
</template>

<script>
export default {
 
  props: {
    diseasesData: Array,
  },

  data() {    
    return {
      diseases: [],
    };
  },
  
  methods:{
        diseasesArray(){
          this.diseasesData.map( (data) => {        
         this.diseases.push(data.Name);
        });
          this.$emit("diseases-dataset", this.diseases);

        }
     },

  mounted() {
      this.diseasesArray()
   },
  computed: {
    diseasesAllData() {      
      return this.diseasesData;
    },  
  }
  
};

</script>

