<template>
  <div
    class="dropdown-menu quick-query-dropdown p-0"
    id="mega_menu_quick_enquiry"
    toggle-parent="mega_toggle_quick_enquiry"
  >
    <div class="px-20 py-20 mb-0" v-if="!enquired">
      <div class="form-group">
        <label class="color-white f-lg-18 font-weight-normal mb-1" for="fname">
          Name
        </label>
        <input
          type="text"
          name="fname"
          class="gw-track"
          data-gw-user-name="Patient Name"
          data-gw-event-name="Patient Name"
          v-model="name"
          :class="[
            isNameGood ? '' : 'is-invalid',
            'form-control f-lg-18 py-lg-2',
          ]"
          maxlength="128"
          @keypress="onKeyDown"
          placeholder="Enter Name*"
          :disabled="submitting"
        />
      </div>
      <div class="form-group">
        <label
          class="color-white f-lg-18 font-weight-normal mb-1"
          for="contact-number"
          >Phone</label
        >
        <input
          type="text"
          name="phone"
          class="gw-track"
          data-gw-user-name="Phone"
          data-gw-event-name="Phone"
          v-model="phone"
          @keypress="phoneOnKeyDown"
          :class="[
            isPhoneGood ? '' : 'is-invalid',
            'form-control f-lg-18 py-lg-2',
          ]"
          ref="teleDial"        
          maxlength="14"
          minlength="5"
          placeholder="Enter mobile number*"
          :disabled="submitting"
        />
        
        <input type="hidden" id="enquiry-country" name="country" class="form-control" placeholder="Select Phone Number"
          readonly value="" data-press-country/>

      </div>

      <span id="phone-error" style="color:white;"></span>

      <input type="hidden" id="quickEnquiry-country" name="country" class="form-control" placeholder="Select Phone Number*" readonly value="" data-press-country />

      <div class="form-group">
        <label
          class="color-white f-lg-18 font-weight-normal mb-1"
          for="contact-number"
          >Preferred Time To Call</label
        >
        <vs-select
          class="time-to-call"
          v-model="timeToCall"
          width="100%"
          placeholder="Preferred Time To Call"
          required
        >
          <vs-select-item
            :key="index"
            :value="item"
            :text="item"
            v-for="(item, index) in timeSlots"
          />
        </vs-select>
      </div>      
      <input type="hidden" data-gw-event-title="Quick Enquiry Submitted" />
      <button
        type="button"
        @click.prevent="submitQuickEnquiry"
        :disabled="submitting || !shouldSubmit"
        class="
          btn-with-icon
          h-40
          py-0
          btn-with-icon-shade
          border-0
          w-100
          max-width-none
          f-lg-20
          btn-with-icon-sm
          ml-auto
          text-center
          d-block
          pl-0
          pr-5
        "
      >
        Submit
      </button>

      <input data-gw-event-name="Agree T&C" type="checkbox" checked class="form-check-input mb-0" id="agreeInt1" name="accept" style="cursor:pointer; margin-left:28px">    
      <div class="text-center mb-2" style="margin-left:50px;">
        <label class="ml-100 form-check-label f-14 f-lg-14 color-white mb-0 agree-checkbox d-flex align-items-center w-100" for="agreeInt1" style="cursor:pointer">      
              <p class="f-13 f-lg-13 mt-1" style="margin-bottom: 0;">Get Updates on Whatsapp. I agree to the <a href="https://www.maxhealthcare.in/terms-and-conditions" style="font-weight:600px"> T&C.</a></p>
        </label>
      </div>     
    <span class="error-msg pl-3 f-17 f-lg-14 ml-4" style="display: none; color: red; margin-top: -8px;">Please check the term and conditions</span>  

<p class="f-lg-15 f-14 color-white hover-no-underline mb-0 mt-3 text-center">Email - digitalquery@maxhealthcare.com</p>

    </div>
    <div v-if="enquired">
      <div class="px-20 py-20 mb-0">
        <div class="form-group">
          <label class="quick-enquiry-submitted">
            Thank you for your enquiry, we will assit you shortly.
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuesax from "vuesax";
import { recaptchaInit } from "../../js/recaptcha.js";
export default {
  components: {
    Vuesax,
  },
  data() {
    return {
      name: "",
      phone: "",
      timeToCall: "",
      phoneWithCC: "",
      enquired: false,
      submitting: false,
      issues: {},
      dialCode: "91",
      intlPD: null,
      currentCountryCode: localStorage.getItem("currentCountryCode") ?? "IN",
    };
  },

  mounted() {
    this.initIntlPd();
  },

  updated() {
    let vi = this;
    if (vi.intlPD) {
      vi.dialCode = vi.intlPD.getSelectedCountryData().dialCode;
    }
  },

  computed: {
    timeSlots() {
      const startAt = moment().startOf("day").add(8, "hours");
      const endAt = moment().endOf("day").subtract(2, "hours");

      const totalHours = parseInt(endAt.diff(startAt, "hours"));
      let start = startAt;
      return Array(totalHours + 1)
        .fill(0)
        .map((_, i) => i)
        .map((idx) => {
          const hold = start.format("hh:mm A");
          start =
            idx === totalHours
              ? start.add(30, "minutes")
              : start.add(1, "hours");
          return `${hold} - ${start.format("hh:mm A")}`;
        });
    },
    isPhoneGood() {
      let vi = this;

      if (typeof vi.phone === "string" && vi.phone.length <= 1) {
        return true;
      }

      if (vi.dialCode == "91") {
        return isFinite(vi.phone) &&
          !vi.issues.hasOwnProperty("phone") &&
            vi.phone.charAt(0) != '0'  &&
          vi.phone.length === 10
          ? true
          : false;
      } else {
        return isFinite(vi.phone) &&
          !vi.issues.hasOwnProperty("phone") &&
          vi.phone.length >= 5 &&
            vi.phone.charAt(0) != '0'  &&
          vi.phone.length <= 14
          ? true
          : false;
      }
    },
    isNameGood() {
      let vi = this;

      if (typeof vi.name === "string" && vi.name.length <= 1) {
        return true;
      }
      return isNaN(vi.name) &&
        !vi.issues.hasOwnProperty("name") &&
        vi.name.length > 0
        ? true
        : false;
    },
    shouldSubmit() {
      let vi = this;

      return vi.isPhoneGood &&
        vi.isNameGood &&
        vi.name.length > 0 &&
        vi.phone.length >= 5 &&
        vi.phone.length <= 14 &&
        vi.phone.charAt(0) != '0'  &&
        vi.timeToCall.length > 0
        ? true
        : false;
    },
  },

  methods: {
    initIntlPd() {
      let vi = this;

      let teleDial = vi.$refs.teleDial;
      const cotactEnquiryCountry = document.getElementById("enquiry-country");
      vi.intlPD = intlTelInput(teleDial, {
        initialCountry: vi.currentCountryCode,
        separateDialCode: true,
      });    
      console.log("teleDial===",vi.intlPD.getSelectedCountryData().name);
              cotactEnquiryCountry.setAttribute('value', vi.intlPD.getSelectedCountryData().name);
              teleDial.setAttribute("dial", `+${vi.intlPD.getSelectedCountryData().dialCode}`);
              teleDial.addEventListener("countrychange", function(e) {
                  teleDial.setAttribute(
                      "dial",
                      `+${vi.intlPD.getSelectedCountryData().dialCode}`
                  );
                  if (teleDial.getAttribute("dial") === "+91") {
                      if (teleDial.value.length >= 10) {
                          teleDial.value = teleDial.value.substring(0, 10);
                      }
                  }
                  cotactEnquiryCountry.setAttribute('value', vi.intlPD.getSelectedCountryData().name);
      });
      const blogCountry = document.getElementById("quickEnquiry-country");
      teleDial.setAttribute(
        "dial",
        `+${vi.intlPD.getSelectedCountryData().dialCode}`
      );
      vi.dialCode = vi.intlPD.getSelectedCountryData().dialCode;


      blogCountry.setAttribute('value', vi.intlPD.getSelectedCountryData().name);

      vi.phoneWithCC =
        "+" + vi.intlPD.getSelectedCountryData().dialCode + vi.phone;

    teleDial.addEventListener("countrychange", function (e) {
    // Update the dial code
    teleDial.setAttribute("dial", `+${vi.intlPD.getSelectedCountryData().dialCode}`);
    
    // Update the country input field
    blogCountry.setAttribute('value', vi.intlPD.getSelectedCountryData().name);

    if (teleDial.getAttribute("dial") === "+91") {
        if (vi.phone.length >= 10) {
            vi.phone = vi.phone.substring(0, 10);
        }
    }

    vi.dialCode = vi.intlPD.getSelectedCountryData().dialCode;
    vi.phoneWithCC = `+${vi.dialCode}${vi.phone}`;
    }.bind(this));
    },
    onKeyDown(e) {
      if (!/^[A-Za-z\s]$/.test(e.key)) e.preventDefault();
    },
    phoneOnKeyDown(e) {
      let vi = this;
      if (/^[0-9]$/i.test(e.key)) {

        if (vi.dialCode == "91") {
          this.phone =
            this.phone.length >= 10
              ? this.phone.substring(0, this.phone.length - 1)
              : this.phone;
        } else {
          this.phone =
            this.phone.length >= 14
              ? this.phone.substring(0, this.phone.length - 1)
              : this.phone;
        }

        vi.dialCode = vi.intlPD.getSelectedCountryData().dialCode;
        vi.phoneWithCC =
          "+" + vi.intlPD.getSelectedCountryData().dialCode + vi.phone;
      } else e.preventDefault();
    },
    async submitQuickEnquiry(e) {
      let vi = this;

      if (!vi.enquired && vi.name.length > 0 && vi.phone.length > 0) {
        grecaptcha.ready(async () => {
          vi.phoneWithCC =
            "+" + vi.intlPD.getSelectedCountryData().dialCode + vi.phone;

          vi.submitting = !vi.submitting;

          const recaptcha = await recaptchaInit("quicky_enquiry");

         dataLayer.push({
          'event': 'Mhc_quick_enquiry_submit'
          });

          let urlParams = new URLSearchParams(window.location.href);
          let gclid_data = urlParams.get('gclid');

          clevertap.event.push("Quick Enquiry", {
              "Name": vi.name.trim(),
              "Phone": vi.phoneWithCC.trim(),
              "TimetoCall": vi.timeToCall.trim(),
              "Gclid": gclid_data,
              "URL": window.location.href
        });

        const selectedCountry = document.getElementById("quickEnquiry-country").value;

          axios
            .post("/contactus-india-submission/ajax_create", {
              "enquiry-type": "QuickEnquiry",

              "contact-type": "enquiry",

              fullname: vi.name.trim(),

              mobile: vi.phoneWithCC.trim(),

              "time-to-call": vi.timeToCall.trim(),

              "country": selectedCountry,

              remarks: "From : " + window.location.href,

              fullURL: window.location.href,

              recaptcha: recaptcha,
            })
            .then(function (response) {
              if (response.data.status == "success") {
                vi.enquired = !vi.enquired;

                vi.submitting = !vi.submitting;

                setTimeout(
                  function () {
                    $("#quick-enquiry-dropdown a").dropdown("toggle");

                    vi.enquired = !true;

                    vi.submitting = !true;

                    vi.name = "";

                    vi.phone = "";

                    vi.phoneWithCC = "";

                    vi.timeToCall = "";

                    vi.intlPD.destroy();

                    vi.intlPD = null;
                  }.bind(this),
                  4000
                );

                setTimeout(
                  function () {
                    vi.initIntlPd();
                  }.bind(this),
                  5000
                );
              }
            })
            .catch(function (error) {
              console.log("error", error);
            });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-menu {
  right: 0;
  left: auto;
}

.quick-enquiry-submitted {
  color: #fff;
  font-weight: normal;
  margin-bottom: 0.25rem !important;
}

@media (min-width: 992px) {
  .quick-enquiry-submitted {
    font-size: 18px !important;
  }
}
</style>
