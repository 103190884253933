<template>
  <div class="bg-green-darken rounded-lg px-lg-20 px-3 pt-20 pb-lg-35 pb-30">
    <div class="col-11 px-0">
    <a href="tel:+918826600461" class="hover-no-underline ">
    <p class="color-white font-weight-bold f-24 mb-lg-10 mb-2">Call : +91 8826600461</p></a>
      <h3 class="color-white font-weight-bold f-24 mb-lg-10 mb-2">
        Contact Us
      </h3>
      <p class="color-white f-lg-17 mb-lg-3 mb-35">
        Please fill the form below for registration
      </p>
      <form>
        <div class="form-group mb-20">
          <input
            type="text"
            name="firstname"
            class="form-control f-16 py-10 mt-3 mb-1"
            :class="[
              $v.form.firstname.$error ? 'is-invalid' : '',
              !$v.form.firstname.$invalid ? 'is-valid' : '',
            ]"
            @input="$v.form.firstname.$touch()"
            @blur="$v.form.firstname.$touch()"
            v-model="$v.form.firstname.$model"
            :disabled="submitting"
            placeholder="Enter First Name*"
            required
          />
          <div v-if="!$v.form.firstname.required" class="invalid-feedback">
            First Name is Required
          </div>
          <div
            v-else-if="!$v.form.firstname.alphaSpace"
            class="invalid-feedback"
          >
            Only Enter Valid Characters
          </div>
          <input
            type="text"
            name="lastname"
            class="form-control f-16 py-10 mt-3 mb-1"
            :class="[
              $v.form.lastname.$error ? 'is-invalid' : '',
              !$v.form.lastname.$invalid ? 'is-valid' : '',
            ]"
            @input="$v.form.lastname.$touch()"
            @blur="$v.form.lastname.$touch()"
            v-model="$v.form.lastname.$model"
            :disabled="submitting"
            placeholder="Enter Last Name*"
            required
          />
          <div v-if="!$v.form.lastname.required" class="invalid-feedback">
            Last Name is Required
          </div>
          <div
            v-else-if="!$v.form.lastname.alphaSpace"
            class="invalid-feedback"
          >
            Only Enter Valid Characters
          </div>
          <input
            type="text"
            name="email"
            class="form-control f-16 py-10 mt-3 mb-3"
            :class="[
              $v.form.email.$error ? 'is-invalid' : '',
              !$v.form.email.$invalid ? 'is-valid' : '',
            ]"
            @input="$v.form.email.$touch()"
            @blur="$v.form.email.$touch()"
            v-model="$v.form.email.$model"
            :disabled="submitting"
            placeholder="Enter Email Id*"
            required
          />
          <div v-if="!$v.form.email.required" class="invalid-feedback">
            Email is Required
          </div>
          <div v-else-if="!$v.form.email.email" class="invalid-feedback">
            Email should be a valid Email
          </div>
          <input
            type="text"
            name="phone"
            class="form-control f-16 py-10 mt-3 mb-1"
            :class="[
              $v.form.phone.$error ? 'is-invalid' : '',
              !$v.form.phone.$invalid ? 'is-valid' : '',
            ]"
            ref="teleDial"
            tele-dial-academic
            maxlength="14"
            minlength="5"
            @input="$v.form.phone.$touch()"
            @blur="$v.form.phone.$touch()"
            v-model="$v.form.phone.$model"
            :disabled="submitting"
            placeholder="Enter Phone Number*"
            required
          />                   
        <span id="phone-error-academic" style="color:white;"></span>
        <div v-if="$v.form.phone.$error && $v.form.phone.$dirty" class="invalid-feedback">
          Phone is Required
        </div>       
        <div v-else-if="!$v.form.phone.minLength && $v.form.phone.$dirty" class="invalid-feedback">
          Phone should be at least {{ $v.form.phone.$params.minLength.min }} digit
        </div>
        <div v-else-if="!$v.form.phone.maxLength && $v.form.phone.$dirty" class="invalid-feedback">
          Phone should be at max {{ $v.form.phone.$params.maxLength.max }} digit
        </div>


        <input type="hidden" id="academic-country" name="country" class="form-control" placeholder="Select Phone Number*" readonly value="" data-press-country />
         
         
          <input
            type="text"
            name="address"
            class="form-control f-16 py-10 mt-3 mb-1"
            :class="[
              $v.form.address.$error ? 'is-invalid' : '',
              !$v.form.address.$invalid ? 'is-valid' : '',
            ]"
            @input="$v.form.address.$touch()"
            @blur="$v.form.address.$touch()"
            v-model="$v.form.address.$model"
            :disabled="submitting"
            placeholder="Enter Address*"
            required
          />
          <div v-if="!$v.form.address.required && $v.form.address.$dirty" class="invalid-feedback">
            Address is Required
          </div>
          <div v-else-if="!$v.form.address.alphaSpace && $v.form.address.$dirty" class="invalid-feedback">
            Only Enter Valid Characters
          </div>
          <input
            type="text"
            name="query"
            class="form-control f-16 py-10 mt-3 mb-1"
            :class="[
              $v.form.query.$error ? 'is-invalid' : '',
              !$v.form.query.$invalid ? 'is-valid' : '',
            ]"
            @input="$v.form.query.$touch()"
            @blur="$v.form.query.$touch()"
            v-model="$v.form.query.$model"
            :disabled="submitting"
            placeholder="Query / Question*"
            required
          />
          <div v-if="!$v.form.query.required && $v.form.query.$dirty" class="invalid-feedback">
            Query is Required
          </div>
          <div v-else-if="!$v.form.query.alphaSpace" class="invalid-feedback">
            Only Enter Valid Characters
          </div>
        </div>
        <div class="form-group mb-20">
          <div class="custom-control custom-checkbox">
            <input
              data-gw-event-name="Agree T&amp;C"
              type="checkbox"
              name="accept"
              class="custom-control-input"
              id="cb"
              required
              :disabled="submitting"
              checked
              :class="[
                $v.form.terms.$error ? 'is-invalid' : '',
                !$v.form.terms.$invalid ? 'is-valid' : '',
              ]"
              @input="$v.form.terms.$touch()"
              @blur="$v.form.terms.$touch()"
              v-model="$v.form.terms.$model"
            />
            <label
              class="
                f-base
                color-white
                font-weight-normal
                custom-control-label custom-control-label-outline-white
              "
              for="cb"
            >
              <p                
                class="color-white hover-color-white hover-no-underline"
                target="_blank" style="font-size: smaller;"
                >Get Updates on Whatsapp. I agree to the <a href="https://www.maxhealthcare.in/terms-and-conditions" class="color-blue">T&C.</a></p
              >
            </label>

            <div v-if="!$v.form.terms.required" class="invalid-feedback">
              Please Agree to the terms
            </div>
          </div>
        </div>
        <button
          @click.prevent="submitAcademicApplication"
          :disabled="submitting && !shouldSubmit"
          type="button"
          class="
            btn-with-icon btn-with-icon-shade
            border-0
            f-lg-20
            w-100
            btn-with-icon-sm btn-with-icon-xs
            py-lg-3
            pl-0
            pr-5
          "
        >
          Submit
        </button>

        <p class="f-lg-16 f-15
    color-white mb-0 mt-3 ">Email - digitalquery@maxhealthcare.com</p>
      </form>
    </div>
    <div class="card shadow my-4" v-if="submitted">
      <div class="card-header bg-info text-light font-weight-bold">
        Your Application has been submitted
      </div>
      <div class="card-body">We'll get back to you soon.</div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
  integer,
  alpha,
  alphaNum,
  helpers,
} from "vuelidate/lib/validators";
const alphaSpace = helpers.regex("alphaSpace", /^[a-zA-Z\s]*$/);
import { recaptchaInit } from "../../js/recaptcha.js";
const boolCheck = (value) => typeof value === "boolean" && value === true;

export default {
  props: { routeData: String },
  data() {
    return {
      form: {
        firstname: "",

        lastname: "",

        email: "",

        phone: "",

        address: "",

        query: "",

        terms: true,
      },

      submitting: false,

      submitted: false,
    };
  },

  validations: {
    form: {
      firstname: {
        required: required,
        alpha: alphaSpace,
      },
      lastname: {
        required: required,
        alpha: alphaSpace,
      },

      phone: {
        required: required,
        number: integer,
        minLength: minLength(5),
        maxLength: maxLength(14),
      },

      email: {
        required: required,
        email: email,
      },

      address: {
        required: required,
      },

      query: {
        required: required,
      },
      terms: {
        required: boolCheck,
      },
    },

    checked: {
      required: required,
    },
  },

  mounted() {},
  computed: {
    shouldSubmit() {
      let vi = this;
      return !vi.submitting && !vi.$v.form.$invalid ? true : false;
    },

    getRoute() {
      let vi = this;
      return vi.isValidRoute
        ? new URL(vi.routeData).pathname
        : new URL(`${window.location.href}/application/submit`).pathname;
    },
    isValidRoute() {
      let vi = this;
      let a = document.createElement("a");
      a.href = vi.routeData;
      return a.host && a.host == window.location.host;
    },
  },

  watch: {
    submitted: function (ols, nes) {
      let vi = this;
      vi.moduleInitState();
    },
  },

  methods: {
    moduleInitState() {
      let vi = this;

      if (vi.submitted === true) {
        vi.$nextTick(() => {
          vi.form.firstname = "";

          vi.form.lastname = "";

          vi.form.email = "";

          vi.form.phone = "";

          vi.form.address = "";

          vi.form.query = "";

          vi.$v.form.$reset();
        });
      }
    },

    async submitAcademicApplication(e) {
      let vi = this;
      vi.formTouch();
       let phoneError = '';

      if (vi.shouldSubmit) {
        grecaptcha.ready(async () => {

         const recaptcha = await recaptchaInit("academic_enquiry");

          let fd = new FormData();

          Object.entries(vi.form).forEach((item, k) => {
            fd.append(item[0], item[1]);
          });

          const selectedCountry = document.getElementById("academic-country").value;
          
          fd.append("recaptcha", recaptcha);
          fd.append("country", selectedCountry);
          vi.submitting = !vi.submitting;

          let phoneNuber = vi.form.phone;           
          let sanitizedPhoneNumber = phoneNuber.replace(/\D/g, '');
          if (selectedCountry == "India (भारत)") {              
              if (sanitizedPhoneNumber && sanitizedPhoneNumber.length != 10) {
                  phoneError = 'Please provide a valid number!';       
              }else{
                  document.getElementById("phone-error-academic").innerHTML = '';
              }
          }
          if(phoneError) {
          document.getElementById("phone-error-academic").innerHTML = phoneError;
          return false;
          }


          axios
            .post(vi.getRoute, fd)
            .then(function (response) {
              if (response.data.status == "success") {
                document.getElementById("phone-error-academic").innerHTML = '';
                vi.submitting = !vi.submitting;
                vi.submitted = !vi.submitted;
                setTimeout(() => {
                  vi.submitted = !vi.submitted;
                }, 3000);

                dataLayer.push({
                'event': 'Mhc_academic_form_submit_button',
                'Mhc_var_academic_form_submit_button':'success'
                });
              } else if (typeof response.data.errors == "object") {
                document.getElementById("phone-error-academic").innerHTML = '';
                vi.submitting = !vi.submitting;
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
              }
            })
            .catch(function (error) {
              console.log("error", error);
               dataLayer.push({
                'event': 'Mhc_academic_form_submit_button',
                'Mhc_var_academic_form_submit_button':error
                });
            });
        });
      }
    },
    formTouch() {
      let vi = this;
      vi.$v.form.$touch();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
