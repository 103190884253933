var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-green-darken rounded-lg px-lg-20 px-3 pt-20 pb-lg-35 pb-30"},[_c('div',{staticClass:"col-11 px-0"},[_vm._m(0),_vm._v(" "),_c('h3',{staticClass:"color-white font-weight-bold f-24 mb-lg-10 mb-2"},[_vm._v("\n      Contact Us\n    ")]),_vm._v(" "),_c('p',{staticClass:"color-white f-lg-17 mb-lg-3 mb-35"},[_vm._v("\n      Please fill the form below for registration\n    ")]),_vm._v(" "),_c('form',[_c('div',{staticClass:"form-group mb-20"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.firstname.$model),expression:"$v.form.firstname.$model"}],staticClass:"form-control f-16 py-10 mt-3 mb-1",class:[
            _vm.$v.form.firstname.$error ? 'is-invalid' : '',
            !_vm.$v.form.firstname.$invalid ? 'is-valid' : '',
          ],attrs:{"type":"text","name":"firstname","disabled":_vm.submitting,"placeholder":"Enter First Name*","required":""},domProps:{"value":(_vm.$v.form.firstname.$model)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.firstname, "$model", $event.target.value)},function($event){return _vm.$v.form.firstname.$touch()}],"blur":function($event){return _vm.$v.form.firstname.$touch()}}}),_vm._v(" "),(!_vm.$v.form.firstname.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n          First Name is Required\n        ")]):(!_vm.$v.form.firstname.alphaSpace)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n          Only Enter Valid Characters\n        ")]):_vm._e(),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.lastname.$model),expression:"$v.form.lastname.$model"}],staticClass:"form-control f-16 py-10 mt-3 mb-1",class:[
            _vm.$v.form.lastname.$error ? 'is-invalid' : '',
            !_vm.$v.form.lastname.$invalid ? 'is-valid' : '',
          ],attrs:{"type":"text","name":"lastname","disabled":_vm.submitting,"placeholder":"Enter Last Name*","required":""},domProps:{"value":(_vm.$v.form.lastname.$model)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.lastname, "$model", $event.target.value)},function($event){return _vm.$v.form.lastname.$touch()}],"blur":function($event){return _vm.$v.form.lastname.$touch()}}}),_vm._v(" "),(!_vm.$v.form.lastname.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n          Last Name is Required\n        ")]):(!_vm.$v.form.lastname.alphaSpace)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n          Only Enter Valid Characters\n        ")]):_vm._e(),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.email.$model),expression:"$v.form.email.$model"}],staticClass:"form-control f-16 py-10 mt-3 mb-3",class:[
            _vm.$v.form.email.$error ? 'is-invalid' : '',
            !_vm.$v.form.email.$invalid ? 'is-valid' : '',
          ],attrs:{"type":"text","name":"email","disabled":_vm.submitting,"placeholder":"Enter Email Id*","required":""},domProps:{"value":(_vm.$v.form.email.$model)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.email, "$model", $event.target.value)},function($event){return _vm.$v.form.email.$touch()}],"blur":function($event){return _vm.$v.form.email.$touch()}}}),_vm._v(" "),(!_vm.$v.form.email.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n          Email is Required\n        ")]):(!_vm.$v.form.email.email)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n          Email should be a valid Email\n        ")]):_vm._e(),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.phone.$model),expression:"$v.form.phone.$model"}],ref:"teleDial",staticClass:"form-control f-16 py-10 mt-3 mb-1",class:[
            _vm.$v.form.phone.$error ? 'is-invalid' : '',
            !_vm.$v.form.phone.$invalid ? 'is-valid' : '',
          ],attrs:{"type":"text","name":"phone","tele-dial-academic":"","maxlength":"14","minlength":"5","disabled":_vm.submitting,"placeholder":"Enter Phone Number*","required":""},domProps:{"value":(_vm.$v.form.phone.$model)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.phone, "$model", $event.target.value)},function($event){return _vm.$v.form.phone.$touch()}],"blur":function($event){return _vm.$v.form.phone.$touch()}}}),_vm._v(" "),_c('span',{staticStyle:{"color":"white"},attrs:{"id":"phone-error-academic"}}),_vm._v(" "),(_vm.$v.form.phone.$error && _vm.$v.form.phone.$dirty)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n        Phone is Required\n      ")]):(!_vm.$v.form.phone.minLength && _vm.$v.form.phone.$dirty)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n        Phone should be at least "+_vm._s(_vm.$v.form.phone.$params.minLength.min)+" digit\n      ")]):(!_vm.$v.form.phone.maxLength && _vm.$v.form.phone.$dirty)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n        Phone should be at max "+_vm._s(_vm.$v.form.phone.$params.maxLength.max)+" digit\n      ")]):_vm._e(),_vm._v(" "),_c('input',{staticClass:"form-control",attrs:{"type":"hidden","id":"academic-country","name":"country","placeholder":"Select Phone Number*","readonly":"","value":"","data-press-country":""}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.address.$model),expression:"$v.form.address.$model"}],staticClass:"form-control f-16 py-10 mt-3 mb-1",class:[
            _vm.$v.form.address.$error ? 'is-invalid' : '',
            !_vm.$v.form.address.$invalid ? 'is-valid' : '',
          ],attrs:{"type":"text","name":"address","disabled":_vm.submitting,"placeholder":"Enter Address*","required":""},domProps:{"value":(_vm.$v.form.address.$model)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.address, "$model", $event.target.value)},function($event){return _vm.$v.form.address.$touch()}],"blur":function($event){return _vm.$v.form.address.$touch()}}}),_vm._v(" "),(!_vm.$v.form.address.required && _vm.$v.form.address.$dirty)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n          Address is Required\n        ")]):(!_vm.$v.form.address.alphaSpace && _vm.$v.form.address.$dirty)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n          Only Enter Valid Characters\n        ")]):_vm._e(),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.query.$model),expression:"$v.form.query.$model"}],staticClass:"form-control f-16 py-10 mt-3 mb-1",class:[
            _vm.$v.form.query.$error ? 'is-invalid' : '',
            !_vm.$v.form.query.$invalid ? 'is-valid' : '',
          ],attrs:{"type":"text","name":"query","disabled":_vm.submitting,"placeholder":"Query / Question*","required":""},domProps:{"value":(_vm.$v.form.query.$model)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.query, "$model", $event.target.value)},function($event){return _vm.$v.form.query.$touch()}],"blur":function($event){return _vm.$v.form.query.$touch()}}}),_vm._v(" "),(!_vm.$v.form.query.required && _vm.$v.form.query.$dirty)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n          Query is Required\n        ")]):(!_vm.$v.form.query.alphaSpace)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n          Only Enter Valid Characters\n        ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"form-group mb-20"},[_c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.terms.$model),expression:"$v.form.terms.$model"}],staticClass:"custom-control-input",class:[
              _vm.$v.form.terms.$error ? 'is-invalid' : '',
              !_vm.$v.form.terms.$invalid ? 'is-valid' : '',
            ],attrs:{"data-gw-event-name":"Agree T&C","type":"checkbox","name":"accept","id":"cb","required":"","disabled":_vm.submitting,"checked":""},domProps:{"checked":Array.isArray(_vm.$v.form.terms.$model)?_vm._i(_vm.$v.form.terms.$model,null)>-1:(_vm.$v.form.terms.$model)},on:{"input":function($event){return _vm.$v.form.terms.$touch()},"blur":function($event){return _vm.$v.form.terms.$touch()},"change":function($event){var $$a=_vm.$v.form.terms.$model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.$v.form.terms, "$model", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.$v.form.terms, "$model", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.$v.form.terms, "$model", $$c)}}}}),_vm._v(" "),_vm._m(1),_vm._v(" "),(!_vm.$v.form.terms.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n            Please Agree to the terms\n          ")]):_vm._e()])]),_vm._v(" "),_c('button',{staticClass:"btn-with-icon btn-with-icon-shade border-0 f-lg-20 w-100 btn-with-icon-sm btn-with-icon-xs py-lg-3 pl-0 pr-5",attrs:{"disabled":_vm.submitting && !_vm.shouldSubmit,"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.submitAcademicApplication.apply(null, arguments)}}},[_vm._v("\n        Submit\n      ")]),_vm._v(" "),_c('p',{staticClass:"f-lg-16 f-15 color-white mb-0 mt-3"},[_vm._v("Email - digitalquery@maxhealthcare.com")])])]),_vm._v(" "),(_vm.submitted)?_c('div',{staticClass:"card shadow my-4"},[_c('div',{staticClass:"card-header bg-info text-light font-weight-bold"},[_vm._v("\n      Your Application has been submitted\n    ")]),_vm._v(" "),_c('div',{staticClass:"card-body"},[_vm._v("We'll get back to you soon.")])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"hover-no-underline",attrs:{"href":"tel:+918826600461"}},[_c('p',{staticClass:"color-white font-weight-bold f-24 mb-lg-10 mb-2"},[_vm._v("Call : +91 8826600461")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"f-base color-white font-weight-normal custom-control-label custom-control-label-outline-white",attrs:{"for":"cb"}},[_c('p',{staticClass:"color-white hover-color-white hover-no-underline",staticStyle:{"font-size":"smaller"},attrs:{"target":"_blank"}},[_vm._v("Get Updates on Whatsapp. I agree to the "),_c('a',{staticClass:"color-blue",attrs:{"href":"https://www.maxhealthcare.in/terms-and-conditions"}},[_vm._v("T&C.")])])])
}]

export { render, staticRenderFns }